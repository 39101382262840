import React, { Fragment, useState, useMemo } from 'react';

import { ApiList } from '../../app/type';

import { Header, Renderers } from './ResourceListTypes';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Menu,
  MenuItem,
  Collapse,
  Divider,
  IconButton,
  CircularProgress,
  Typography,
} from '@mui/material';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamation, faAngleUp, faAngleDown } from '@fortawesome/free-solid-svg-icons';

import tStyles from './TableList.module.scss';
import variables from '../styles/variables.module.scss';

interface TableRowProps<T, R> {
  res: any;
  headersToUse: Header[];
  onItemSelect: (key: string, res: T) => () => void;
  cellRenders: Renderers<T>;
  hasActions: boolean;
  filteredOptions: (res: T) => Header[] | undefined;
  menuEl: { [key: string]: any };
  openMenu: (key: string, event: any) => void;
  closeMenu: () => void;
  getCollapseResources?: (resourceId: string) => Promise<ApiList<R>>;
  listCollapseHeaders?: (Header | null)[];
  listCollapseColumns?: Renderers<R>;
  listCollapseActionsHeaders?: (res?: R) => ({ key?: string; label?: string } | null)[];
  listCollapseActions?: { [key: string]: (object: R) => void };
}

export const Row = <T, R>(props: TableRowProps<T, R>): React.ReactElement => {
  const [collapseOpen, setCollapseOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [collapseResources, setCollapseResources] = useState<R[]>([]);
  const hasCollapse = props.getCollapseResources ? true : false;
  const defaultRender = (key: string) => (res: any) => res[key] || '-';
  const [menuEl, setMenuEl] = useState<{ [key: string]: any }>({});
  const openMenu = (key: string, event: any) => setMenuEl({ [key]: event?.currentTarget });
  const closeMenu = () => setMenuEl({});
  const hasCollapseActions = props.listCollapseActionsHeaders
    ? props.listCollapseActionsHeaders?.().length > 0
    : false;

  const handleCollapse = async (id: string) => {
    if (!collapseOpen && props.getCollapseResources) {
      setLoading(true);
      await props
        .getCollapseResources(id)
        .then((data: ApiList<R>) => {
          setCollapseResources(data.data);
        })
        .catch(console.error)
        .finally(() => {
          setLoading(false);
        });
    }
    setCollapseOpen(!collapseOpen);
  };

  const collapseHeadersToUse = useMemo((): Header[] => {
    return (props.listCollapseHeaders || []).filter((h) => h) as Header[];
  }, [props.listCollapseHeaders]);

  const collapseCellRenders = useMemo((): Renderers<T> => {
    const filtered = (props.listCollapseHeaders || []).filter((h) => h) as Header[];
    return filtered.reduce((hash: Renderers<T>, header: Header) => {
      let renderer = defaultRender(header.key);
      if (props.listCollapseColumns?.[header.key])
        renderer = (props.listCollapseColumns || {})[header.key];
      return { ...hash, [header.key]: renderer };
    }, {});
  }, [props.listCollapseHeaders]);

  const filteredCollapseActions = (res: R) => {
    if (props.listCollapseActionsHeaders)
      return props.listCollapseActionsHeaders(res).filter((o) => o?.key && o?.label) as Header[];
  };

  const onCollpaseActionSelect = (key: string, res: R) => () => {
    closeMenu();
    if (props.listCollapseActions) props.listCollapseActions[key]?.(res);
  };

  return (
    <Fragment>
      <TableRow>
        {props.headersToUse.map((header: Header) => (
          <TableCell key={header.key}>{props.cellRenders[header.key]?.(props.res)}</TableCell>
        ))}
        {hasCollapse ? (
          <TableCell>
            {loading ? (
              <CircularProgress size={18} />
            ) : (
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => handleCollapse(props.res.id)}
                sx={{
                  backgroundColor: variables.lightGray,
                  color: variables.simplewhite,
                  width: '25px',
                  height: '25px',
                }}
              >
                {collapseOpen ? (
                  <FontAwesomeIcon icon={faAngleUp} />
                ) : (
                  <FontAwesomeIcon icon={faAngleDown} />
                )}
              </IconButton>
            )}
          </TableCell>
        ) : null}
        {props.hasActions && (
          <Fragment>
            <TableCell />
            {props.filteredOptions(props.res)?.length === 0 ? (
              <div></div>
            ) : props.filteredOptions(props.res)?.length !== 1 ? (
              <Fragment>
                <TableCell
                  className={`${Boolean(props.menuEl[props.res.id]) && tStyles.selected}`}
                  align="right"
                  onClick={(event: any) => props.openMenu(props.res.id, event)}
                >
                  Acciones
                </TableCell>
                <Menu
                  anchorEl={props.menuEl[props.res.id]}
                  open={Boolean(props.menuEl[props.res.id])}
                  onClose={props.closeMenu}
                >
                  {props.filteredOptions(props.res)?.map((option, index) => {
                    return (
                      <div key={option.key}>
                        {index === 0 ? null : <Divider />}
                        <MenuItem
                          onClick={props.onItemSelect(option.key, props.res)}
                          sx={{ color: `${variables[option.color || '']}` }}
                        >
                          {option.icon && (
                            <FontAwesomeIcon icon={option.icon} style={{ marginRight: '10px' }} />
                          )}
                          {option.label}
                        </MenuItem>
                      </div>
                    );
                  })}
                </Menu>
              </Fragment>
            ) : (
              <TableCell
                onClick={props.onItemSelect(
                  props.filteredOptions(props.res)?.[0].key || '',
                  props.res
                )}
              >
                {props.filteredOptions(props.res)?.[0].icon && (
                  <FontAwesomeIcon
                    icon={props.filteredOptions(props.res)?.[0].icon || faExclamation}
                    style={{ marginRight: '10px' }}
                  />
                )}
                {props.filteredOptions(props.res)?.[0].label}
              </TableCell>
            )}
          </Fragment>
        )}
      </TableRow>
      <TableRow className={tStyles.collapsedTable}>
        <TableCell style={{ padding: 0, boxShadow: 'none' }} colSpan={4}>
          <Collapse in={collapseOpen} timeout="auto" unmountOnExit>
            {collapseResources.length != 0 ? (
              <Table size="small">
                <TableHead>
                  <TableRow>
                    {collapseHeadersToUse.map((header: Header) => (
                      <TableCell key={header.key}>{header.label}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {collapseResources.map((resource: any) => (
                    <TableRow key={resource.id}>
                      {collapseHeadersToUse.map((header: Header) => (
                        <TableCell key={header.key}>
                          {collapseCellRenders[header.key]?.(resource)}
                        </TableCell>
                      ))}
                      {hasCollapseActions && (
                        <Fragment>
                          <TableCell />
                          {filteredCollapseActions(resource)?.length ? (
                            filteredCollapseActions(resource)?.length !== 1 ? (
                              <Fragment>
                                <TableCell
                                  className={`${Boolean(menuEl[resource.id]) && tStyles.selected}`}
                                  align="right"
                                  onClick={(event: any) => openMenu(resource.id, event)}
                                >
                                  Acciones
                                </TableCell>
                                <Menu
                                  anchorEl={menuEl[resource.id]}
                                  open={Boolean(menuEl[resource.id])}
                                  onClose={closeMenu}
                                >
                                  {filteredCollapseActions(resource)?.map((option, index) => {
                                    return (
                                      <div key={option.key}>
                                        {index === 0 ? null : <Divider />}
                                        <MenuItem
                                          onClick={onCollpaseActionSelect(option.key, resource)}
                                          sx={{ color: `${variables[option.color || '']}` }}
                                        >
                                          {option.icon && (
                                            <FontAwesomeIcon
                                              icon={option.icon}
                                              style={{ marginRight: '10px' }}
                                            />
                                          )}
                                          {option.label}
                                        </MenuItem>
                                      </div>
                                    );
                                  })}
                                </Menu>
                              </Fragment>
                            ) : (
                              <TableCell
                                onClick={onCollpaseActionSelect(
                                  filteredCollapseActions(resource)?.[0].key || '',
                                  resource
                                )}
                                className={tStyles.collapseAction}
                              >
                                <u>{filteredCollapseActions(resource)?.[0].label}</u>
                              </TableCell>
                            )
                          ) : null}
                        </Fragment>
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            ) : (
              <Typography
                variant="h6"
                style={{ textAlign: 'left', marginLeft: 2, backgroundColor: 'white' }}
              >
                ¡No hay pagos!
              </Typography>
            )}
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  );
};

export default TableRow;
