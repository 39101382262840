import React, { Fragment, useState } from 'react';

import { HelpCircle as HelpIcon } from 'react-feather';
import { IconButton, Popover, Typography } from '@mui/material';

import {
  DESCRIPTIONS,
  SUBSCRIPTIONS_DESCRIPTIONS,
  STATEMENTS_DESCRIPTIONS,
  SINGLE_PAYMENT_DESCRIPTIONS,
} from '../constants/descriptions';

interface InfoButtonProps {
  text: keyof { [key: string]: string };
  context: string;
}

const textByContext: { [k: string]: { [s: string]: string } } = {
  product: DESCRIPTIONS,
  subscription: SUBSCRIPTIONS_DESCRIPTIONS,
  statement: STATEMENTS_DESCRIPTIONS,
  singlePayment: SINGLE_PAYMENT_DESCRIPTIONS,
};

export const InfoButton = ({ text, context }: InfoButtonProps): React.ReactElement => {
  const [menuEl, setMenuEl] = useState(null);
  const openPopover = (event: any) => setMenuEl(event?.currentTarget);
  const closePopover = () => setMenuEl(null);

  const displayText = textByContext[context]?.[text] || '';

  return (
    <Fragment>
      <IconButton size="medium" color="primary" onClick={openPopover}>
        <HelpIcon />
      </IconButton>
      <Popover
        sx={{ maxWidth: 1000 }}
        keepMounted
        anchorEl={menuEl}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        open={Boolean(menuEl)}
        onClose={closePopover}
      >
        <Typography margin={1} fontSize={'small'}>
          {displayText}
        </Typography>
      </Popover>
    </Fragment>
  );
};
