import variables from '../styles/variables.module.scss';

export const STATUS = [
  ['pending', 'Pendiente'],
  ['active', 'Activa'],
  ['indebt', 'Con deuda'],
  ['canceled', 'Cancelada'],
  ['active_pending', 'En cola'],
  ['finished', 'Terminada'],
  ['no_debt', 'Al día'],
  ['paused', 'Pausada'],
];

export const STATUS_COLORS = Object.fromEntries([
  ['pending', variables.pending],
  ['active', variables.success],
  ['indebt', variables.warning],
  ['canceled', variables.error],
  ['active_pending', variables.pending],
  ['finished', variables.lightygray],
  ['no_debt', variables.success],
  ['paused', variables.pending],
]);

export const STATUS_ES = Object.fromEntries(STATUS);
