import React, { useEffect, useState, useRef, Fragment } from 'react';
import { useDispatch, useSelector } from '../../../app/hooks';
import { Link, useParams, useHistory } from 'react-router-dom';
import { ApiObject, PaymentPlan, Statement } from '../../../app/type';
import { useSnackbar } from 'notistack';
import { useFormik } from 'formik';
import { DateTime } from 'luxon';
import copyToClipboard from 'copy-text-to-clipboard';

import { sellerApi } from '../../../common/api';
import { SellerState, setPaymentPlan } from '../sellerSlice';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import useMediaQuery from '@mui/material/useMediaQuery';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import InputAdornment from '@mui/material/InputAdornment';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBagShopping, faCreditCard } from '@fortawesome/free-solid-svg-icons';
import { faPenToSquare, faTrashCan, faCopy } from '@fortawesome/free-regular-svg-icons';

import styles from './Index.module.scss';
import cStyles from '../../../common/styles/common.module.scss';
import variables from '../../../common/styles/variables.module.scss';
import {
  formatCurrency,
  formatNumber,
  plainNumberFormatter,
  decimalNumberFormatter,
  formatDecimalNumber,
} from '../../../common/utils';
import { CURRENCIES } from '../../../common/constants/currencies';

import Stepper from '../../../common/components/Stepper';
import InfoBox from '../../../common/components/InfoBox';
import ResourceList from '../../../common/components/ResourceList';
import StatusLabel from '../../../common/components/StatusLabel';
import PopUp from '../../../common/components/PopUp';
import { Button } from '@mui/material';
import { FRONT_URL } from '../../../common/api/client';
import Avatar from '../../../common/components/Avatar';

const Show = (): React.ReactElement => {
  const { paymentPlan, company } = useSelector(({ seller }: { seller: SellerState }) => seller);
  const { paymentPlanId } = useParams<{ paymentPlanId: string }>();
  const isMobile = useMediaQuery(`(max-width:${variables.breakpointMedium})`);
  const [loading, setLoading] = useState<boolean>(false);
  const statementsRef = useRef<{ reloadResource: () => void }>();
  const dispatch = useDispatch();
  const [infoBoxesData, setInfoBoxes] = useState<{ [key: string]: number }>({});
  const { enqueueSnackbar } = useSnackbar();
  const [dialogOpen, setOpenDialog] = useState<boolean>(false);
  const [dialogContext, setDialogContext] = useState<string>();
  const [statement, setStatement] = useState<Statement>();
  const [openSendEmail, setOpenSendEmail] = useState<boolean>(false);
  const portalLink = `${FRONT_URL}/client/login?company_id=${company?.id}`;

  useEffect(() => {
    if (paymentPlanId) {
      setLoading(true);
      sellerApi.paymentPlans
        .show(paymentPlanId)
        .then((data: ApiObject<PaymentPlan>) => {
          dispatch(setPaymentPlan(data.data));
        })
        .catch(() => {
          enqueueSnackbar('Ocurrió un error al obtener el servicio', { variant: 'error' });
        })
        .finally(() => {
          setLoading(false);
        });
      sellerApi.paymentPlans
        .showInfoBoxes(paymentPlanId)
        .then((data) => setInfoBoxes(data.data))
        .catch((error) => console.error(error));
    }
  }, [paymentPlanId]);

  const sendClientPortalEmail = (companyId: string, buyerId: string) => {
    sellerApi.companies
      .sendClientPortalEmail(companyId, buyerId)
      .then(() => {
        enqueueSnackbar('Se enviará el correo', { variant: 'info' });
      })
      .catch(() => {
        enqueueSnackbar('Correo no pudo ser enviado', { variant: 'error' });
      });
  };

  const onLinkCopy = () => {
    copyToClipboard(portalLink);
    enqueueSnackbar('Enlace copiado', { variant: 'info' });
  };

  const boxes = (width?: number) => [
    <InfoBox
      key={1}
      title="TOTAL RECAUDADO"
      data={formatCurrency(infoBoxesData.total_gathered || 0)}
      kind="info2"
      style={styles.firstInfoContainer}
      icon={<FontAwesomeIcon icon={faBagShopping} className="icon" />}
      width={width}
    />,
    <InfoBox
      key={2}
      title="TOTAL DEUDA"
      data={formatCurrency(infoBoxesData.total_debt || 0)}
      kind="info2"
      style={styles.secondInfoContainer}
      icon={<FontAwesomeIcon icon={faCreditCard} className="icon" />}
      width={width}
    />,
    <InfoBox
      key={3}
      title="Agregar cuota"
      function={() => {
        setOpenDialog(true);
        setDialogContext('create');
      }}
      kind="link"
      image="https://storage.googleapis.com/onlypays-public/assets/images/girl%20and%20boy%20working%20together%20in%20front%20of%20laptop.svg"
      style={styles.linkContainer}
      width={width}
    />,
  ];

  useEffect(() => {
    if (dialogOpen === false) {
      setStatement(undefined);
    }
  }, [dialogOpen]);

  return (
    <>
      <Grid container spacing={3} sx={{ mb: 3 }}>
        <Grid item xs={12}>
          <Paper className={cStyles.infoPaper}>
            {/* HEADER */}
            <Grid container>
              <Grid item xs={12}>
                <div className={cStyles.paperHeader}>
                  <Avatar
                    className={cStyles.paperHeaderAvatar}
                    text={paymentPlan?.product?.name}
                    img={paymentPlan?.product?.image}
                    context="product"
                  />
                  <div className={cStyles.paperHeaderContent}>
                    <Typography variant={isMobile ? 'h6' : 'h5'}>
                      <b>{paymentPlan?.product?.name}</b>
                    </Typography>
                    {!isMobile && (
                      <Typography variant="subtitle1">
                        {paymentPlan?.product?.description}
                      </Typography>
                    )}
                  </div>
                  <div className={cStyles.paperHeaderActions}>
                    <div className={`${cStyles.baseAction} ${cStyles.editAction}`}>
                      <IconButton
                        size="medium"
                        disabled={loading}
                        component={Link}
                        to={`/seller/payment_plans/${paymentPlan?.id}/edit`}
                        className={`${cStyles.icon} ${cStyles.editIcon}`}
                      >
                        {loading ? (
                          <CircularProgress size={20} />
                        ) : (
                          <FontAwesomeIcon icon={faPenToSquare} />
                        )}
                      </IconButton>
                      <Typography variant="body2">Editar</Typography>
                    </div>
                    <div className={`${cStyles.baseAction} ${cStyles.deleteAction}`}>
                      <IconButton
                        size="medium"
                        disabled={loading}
                        onClick={() => {
                          setDialogContext('deletePaymentPlan');
                          setOpenDialog(true);
                        }}
                        className={`${cStyles.icon} ${cStyles.deleteIcon}`}
                      >
                        {loading ? (
                          <CircularProgress size={20} />
                        ) : (
                          <FontAwesomeIcon icon={faTrashCan} />
                        )}
                      </IconButton>
                      <Typography variant="body2">Eliminar</Typography>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>

            <Grid container className={cStyles.infoContainer}>
              {isMobile && paymentPlan?.product?.description ? (
                <Grid item xs={12}>
                  <Typography variant="caption">Descripción:</Typography>
                  <Typography variant="h6">{paymentPlan?.product?.description}</Typography>
                </Grid>
              ) : null}
              {Object.keys(paymentPlan?.product.extra_fields || {}).length > 0 && (
                <Grid item xs={12}>
                  <Typography variant="h6" className={styles.infoContainerTitle}>
                    Datos adicionales del plan de pago
                  </Typography>
                </Grid>
              )}
              {Object.keys(paymentPlan?.product.extra_fields || {}).map((key) => (
                <Grid item xs={6} md={4} key={key}>
                  <Typography variant="caption">
                    {paymentPlan?.product.extra_fields?.[key]}
                  </Typography>
                  <Typography variant="subtitle1">
                    <b>{paymentPlan?.extra_fields[key]}</b>
                  </Typography>
                </Grid>
              ))}
              <Grid item xs={12}>
                <Typography variant="h6" className={styles.infoContainerTitle}>
                  Datos del cliente
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant="caption">Nombre del cliente</Typography>
                <Typography variant="subtitle1">
                  <b>{paymentPlan?.buyer.name}</b>
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant="caption">Correo del cliente</Typography>
                <Typography variant="subtitle1">{paymentPlan?.buyer.email}</Typography>
              </Grid>
              {paymentPlan?.bank_information &&
                company?.default_bank_information?.id !== paymentPlan?.bank_information.id && (
                  <>
                    <Grid item xs={12}>
                      <Typography variant="h6" className={styles.infoContainerTitle}>
                        Datos bancarios
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Typography variant="caption">Nombre titular</Typography>
                      <Typography variant="subtitle1">
                        <b>{paymentPlan?.bank_information.name}</b>
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Typography variant="caption">RUT</Typography>
                      <Typography variant="subtitle1">
                        {paymentPlan?.bank_information.tax_id}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Typography variant="caption">Banco</Typography>
                      <Typography variant="subtitle1">
                        {paymentPlan?.bank_information.bank}
                      </Typography>
                    </Grid>
                  </>
                )}
              {paymentPlan?.billing_information &&
                company?.default_billing_information?.id !==
                  paymentPlan?.billing_information.id && (
                  <>
                    <Grid item xs={12}>
                      <Typography variant="h6" className={styles.infoContainerTitle}>
                        Datos de facturación
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Typography variant="caption">Razón social</Typography>
                      <Typography variant="subtitle1">
                        <b>{paymentPlan?.billing_information.business_name}</b>
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Typography variant="caption">RUT</Typography>
                      <Typography variant="subtitle1">
                        {paymentPlan?.billing_information.tax_id}
                      </Typography>
                    </Grid>
                  </>
                )}
              <Grid item xs={12} mt={2}>
                <Typography variant="caption">Link portal del cliente</Typography>
                <div className={cStyles.linkContainer}>
                  <IconButton
                    size="medium"
                    disabled={loading}
                    className={cStyles.copyIcon}
                    onClick={onLinkCopy}
                  >
                    {loading ? <CircularProgress size={20} /> : <FontAwesomeIcon icon={faCopy} />}
                  </IconButton>
                  <Typography variant="h6">
                    <a
                      className={styles.clientPortalLink}
                      href={portalLink}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {portalLink}
                    </a>
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={12} mt={1}>
                <Typography variant="caption">Enviar correo a cliente con su portal</Typography>
                <Typography variant="subtitle1">
                  <Button className={styles.mailButton} onClick={() => setOpenSendEmail(true)}>
                    Enviar correo
                  </Button>
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>

      {isMobile ? (
        <Stepper assets={boxes(12).slice(0, -1)} />
      ) : (
        <Grid container className={styles.containerGrid} flexWrap={'nowrap'}>
          {boxes().map((box) => box)}
        </Grid>
      )}

      {isMobile ? (
        <Button
          className={styles.toButton}
          onClick={() => {
            setOpenDialog(true);
            setDialogContext('create');
          }}
          variant="contained"
        >
          Agregar cuota
        </Button>
      ) : null}

      <ResourceList
        title="Cuotas"
        queryFields=""
        excelDownloadMethod={(_, parentId) =>
          sellerApi.paymentPlans.generateStatementsExcel(parentId)
        }
        getResourceList={(id, _, page) => sellerApi.paymentPlans.statements(id, page)}
        listHeaders={[
          { key: 'number', label: 'Cuota' },
          { key: 'amount', label: 'Monto' },
          { key: 'due_date', label: 'Fecha de vencimiento' },
          { key: 'status', label: 'Estado' },
        ]}
        listColumns={{
          number: (res) => res.installment_number,
          amount: (res: Statement) =>
            formatCurrency(res.currency === 'UF' ? res.external_amount : res.amount, res.currency),
          due_date: (res) => res.due_date,
          status: (res) => <StatusLabel type="statement" status={res.status} />,
          name: (res) => res.due_date,
        }}
        resourceParent={paymentPlan}
        innerRef={statementsRef}
        listActionsHeaders={(res) => {
          if (res) {
            return [
              res.status === 'pending'
                ? { key: 'edit', label: 'Editar cuota', icon: faPenToSquare }
                : null,
              res.status === 'pending'
                ? {
                    key: 'delete',
                    label: 'Eliminar cuota',
                    icon: faTrashCan,
                  }
                : null,
            ];
          }
          return [null];
        }}
        listActions={{
          edit: (res) => {
            setStatement(res);
            setDialogContext('edit');
            setOpenDialog(true);
          },
          delete: (res) => {
            setStatement(res);
            setDialogContext('deleteStatement');
            setOpenDialog(true);
          },
        }}
        hideQuery
        listMobileHeaders={[{ key: 'name' }, { key: 'amount' }, { key: 'status' }]}
      />
      <StatementPopUp
        context={dialogContext}
        state={{ open: dialogOpen, setOpen: setOpenDialog }}
        innerRef={statementsRef}
        statement={statement}
        paymentPlanId={paymentPlanId}
        companyId={company?.id}
      />
      <PopUp
        state={{ open: openSendEmail, setOpen: setOpenSendEmail }}
        content={
          <Fragment>
            <Typography sx={{ marginTop: '20px' }} variant="body1" align="center">
              Se enviará un correo con el link del portal del cliente a {paymentPlan?.buyer.email}.
            </Typography>
          </Fragment>
        }
        extraActions={[
          <Button onClick={() => setOpenSendEmail(false)} variant="outlined" key={2}>
            Cancelar
          </Button>,
          <Button
            onClick={() => {
              sendClientPortalEmail(company?.id || '', paymentPlan?.buyer.id || '');
              setOpenSendEmail(false);
            }}
            color="info"
            variant="contained"
            key={1}
          >
            Enviar
          </Button>,
        ]}
      />
    </>
  );
};

interface StatementPopUpProps {
  state: {
    open: boolean;
    setOpen: (s: boolean) => void;
  };
  statement?: Statement;
  paymentPlanId?: string;
  companyId?: string;
  context?: string;
  innerRef: React.MutableRefObject<{ reloadResource: () => void } | undefined>;
}

const StatementPopUp = (props: StatementPopUpProps): React.ReactElement => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const createStatement = (values: { [key: string]: string | number | Date | undefined }) => {
    sellerApi.paymentPlans
      .createStatement(props.paymentPlanId || '', { data: values })
      .then(() => {
        enqueueSnackbar('Cuota guardada correctamente', { variant: 'success' });
        props.innerRef.current?.reloadResource();
      })
      .catch(() => {
        enqueueSnackbar('Ha ocurrido un error, intenta nuevamente', { variant: 'error' });
      });
  };

  const editStatement = (values: { [key: string]: string | number | Date | undefined }) => {
    sellerApi.paymentPlans
      .editStatement(props.paymentPlanId || '', values)
      .then(() => {
        enqueueSnackbar('Cuota actualizada correctamente', { variant: 'success' });
        props.innerRef.current?.reloadResource();
      })
      .catch(() => {
        enqueueSnackbar('Ha ocurrido un error, intenta nuevamente', { variant: 'error' });
      });
  };

  const deleteStatement = (id: string) => {
    if (id && props.paymentPlanId) {
      sellerApi.paymentPlans
        .deleteStatement(props.paymentPlanId, id)
        .then(() => {
          enqueueSnackbar('Cuota borrada correctamente', { variant: 'success' });
          props.innerRef.current?.reloadResource();
        })
        .catch(() => {
          enqueueSnackbar('Ha ocurrido un error, intenta nuevamente', { variant: 'error' });
        })
        .finally(() => props.state.setOpen(false));
    }
  };

  const deletePaymentPlan = () => {
    if (props.paymentPlanId) {
      sellerApi.paymentPlans
        .deletePaymentPlan(props.paymentPlanId)
        .then(() => {
          enqueueSnackbar('Plan de pago eliminado correctamente', { variant: 'success' });
          history.push('/seller/payment_plans');
        })
        .catch((error) => {
          if (error.response.status === 500) {
            enqueueSnackbar('No puedes eliminar un plan de pagos que tenga cuotas pagadas', {
              variant: 'error',
            });
          } else {
            enqueueSnackbar('Ha ocurrido un error, intenta nuevamente', { variant: 'error' });
          }
        })
        .finally(() => props.state.setOpen(false));
    }
  };

  const formik = useFormik({
    initialValues: {
      id: props.statement?.id || undefined,
      amount: props.statement?.amount || 0,
      currency: props.statement?.currency || 'CLP',
      due_date: props.statement?.due_date || undefined,
      company_id: props.companyId,
      external_amount: props.statement?.external_amount || 0,
    },
    onSubmit: (values) => {
      if (props.context === 'create') {
        createStatement(values);
      } else if (props.context === 'edit') {
        editStatement(values);
      }
      props.state.setOpen(false);
      formik.resetForm();
    },
  });

  useEffect(() => {
    if (props.statement) {
      Object.keys(formik.values).forEach((key: string) => {
        if (key !== 'due_date') {
          formik.setFieldValue(key, props.statement?.[key as keyof Statement] || undefined);
        } else {
          const date = DateTime.fromFormat(
            props.statement?.due_date || '',
            'dd/MM/yyyy'
          ).toISODate();
          formik.setFieldValue('due_date', date);
        }
      });
    } else {
      formik.resetForm();
    }
  }, [props.statement]);

  const render = () => {
    if (props.context === 'create') {
      return (
        <div className={styles.statementForm}>
          <Typography>Crear nueva cuota</Typography>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              formik.submitForm();
            }}
            className={styles.form}
          >
            <TextField
              select
              required
              value={formik.values.currency}
              id="currency"
              variant="outlined"
              autoComplete="currency"
              label="Moneda"
              onChange={(event) => formik.setFieldValue('currency', event.target.value)}
              error={formik.touched.amount && Boolean(formik.errors.amount)}
              helperText={formik.touched.amount && formik.errors.amount}
            >
              {CURRENCIES.map((curr: string) => (
                <MenuItem key={curr} value={curr}>
                  {curr}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              required
              label="Monto"
              value={
                formik.values.currency === 'UF'
                  ? formatDecimalNumber(formik.values.external_amount)
                  : formatNumber(formik.values.external_amount)
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {formik.values.currency === 'CLP' ? '$' : null}
                  </InputAdornment>
                ),
              }}
              onChange={(event) => {
                const amount =
                  formik.values.currency === 'UF'
                    ? decimalNumberFormatter(event.target.value)
                    : parseInt(plainNumberFormatter(event.target.value)) || 0;
                formik.setFieldValue('amount', amount);
                formik.setFieldValue('external_amount', amount);
              }}
              id="amount"
              autoComplete="amount"
              variant="outlined"
              error={formik.touched.amount && Boolean(formik.errors.amount)}
              helperText={formik.touched.amount && formik.errors.amount}
            />
            <TextField
              required
              label="Fecha de vencimiento"
              value={formik.values.due_date}
              onChange={formik.handleChange}
              id="due_date"
              type="date"
              autoComplete="due_date"
              variant="outlined"
              error={formik.touched.due_date && Boolean(formik.errors.due_date)}
              helperText={formik.touched.due_date && formik.errors.due_date}
              InputLabelProps={{ shrink: true }}
            />
            <div className={styles.actionsEdit}>
              <Button variant="outlined" onClick={() => props.state.setOpen(false)}>
                Cancelar
              </Button>
              <Button variant="contained" type="submit">
                Guardar
              </Button>
            </div>
          </form>
        </div>
      );
    } else if (props.context === 'edit') {
      return (
        <div className={styles.statementForm}>
          <Typography>Editar cuota</Typography>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              formik.submitForm();
            }}
            className={styles.form}
          >
            <TextField
              select
              required
              value={formik.values.currency}
              id="currency"
              variant="outlined"
              autoComplete="currency"
              label="Moneda"
              onChange={(event) => formik.setFieldValue('currency', event.target.value)}
              error={formik.touched.amount && Boolean(formik.errors.amount)}
              helperText={formik.touched.amount && formik.errors.amount}
            >
              {CURRENCIES.map((curr: string) => (
                <MenuItem key={curr} value={curr}>
                  {curr}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              required
              label="Monto"
              value={
                formik.values.currency === 'UF'
                  ? formatDecimalNumber(formik.values.external_amount)
                  : formatNumber(formik.values.external_amount)
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {formik.values.currency === 'CLP' ? '$' : null}
                  </InputAdornment>
                ),
              }}
              onChange={(event) => {
                const amount =
                  formik.values.currency === 'UF'
                    ? decimalNumberFormatter(event.target.value)
                    : plainNumberFormatter(event.target.value);
                formik.setFieldValue('amount', amount);
                formik.setFieldValue('external_amount', amount);
              }}
              id="amount"
              autoComplete="amount"
              variant="outlined"
              error={formik.touched.amount && Boolean(formik.errors.amount)}
              helperText={formik.touched.amount && formik.errors.amount}
            />
            <TextField
              required
              label="Fecha de vencimiento"
              value={formik.values.due_date}
              onChange={formik.handleChange}
              id="due_date"
              type="date"
              autoComplete="due_date"
              variant="outlined"
              error={formik.touched.due_date && Boolean(formik.errors.due_date)}
              helperText={formik.touched.due_date && formik.errors.due_date}
              InputLabelProps={{ shrink: true }}
            />
            <div className={styles.actionsEdit}>
              <Button variant="outlined" onClick={() => props.state.setOpen(false)}>
                Cancelar
              </Button>
              <Button variant="contained" type="submit">
                Guardar
              </Button>
            </div>
          </form>
        </div>
      );
    } else if (props.context === 'deleteStatement') {
      return (
        <>
          <Typography>
            ¿Estás seguro que quieres eliminar esta cuota? Esta acción es irreversible
          </Typography>
          <div>
            <Button key={1} variant="outlined" onClick={() => props.state.setOpen(false)}>
              Cancelar
            </Button>
            <Button variant="contained" onClick={() => deleteStatement(props.statement?.id || '')}>
              Sí
            </Button>
          </div>
        </>
      );
    } else if (props.context === 'deletePaymentPlan') {
      return (
        <>
          <Typography>
            ¿Estás seguro que quieres eliminar este plan de pago? Esta acción es irreversible
          </Typography>
          <div>
            <Button key={1} variant="outlined" onClick={() => props.state.setOpen(false)}>
              Cancelar
            </Button>
            <Button key={2} variant="contained" onClick={deletePaymentPlan}>
              Sí
            </Button>
          </div>
        </>
      );
    }
    return <div></div>;
  };

  return <PopUp content={render()} state={props.state} />;
};

export default Show;
