import React from 'react';

import variables from '../styles/variables.module.scss';

import { STATUS_ES, STATUS_COLORS } from '../constants/subscriptions';
import {
  STATUS_ES as SP_STATUS_ES,
  STATUS_COLORS as SP_STATUS_COLORS,
} from '../constants/subscriptions';
import {
  STATUS_ES as S_STATUS_ES,
  STATUS_COLORS as S_STATUS_COLORS,
  CLIENT_STATUS,
} from '../constants/statements';
import { P_STATUS_COLORS, P_STATUS_ES } from '../constants/products';
import { INVOICE_COLORS, INVOICE_ES } from '../constants/invoices';
import {
  STATUS_ES as SHK_STATUS_ES,
  STATUS_COLORS as SHK_STATUS_COLORS,
} from '../constants/transfer';
import {
  STATUS_ES as PP_STATUS_ES,
  STATUS_COLORS as PP_STATUS_COLORS,
} from '../constants/paymentPlans';
import {
  STATUS_ES as SB_STATUS_ES,
  STATUS_COLORS as SB_STATUS_COLORS,
} from '../constants/subscriptionBuyers';
import {
  STATUS_ES as SINGLE_PAYMENT_STATUS_ES,
  STATUS_COLORS as SINGLE_PAYMENT_STATUS_COLORS,
} from '../constants/singlePaymentBuyers';
import {
  STATUS_ES as PT_STATUS_ES,
  STATUS_COLORS as PT_STATUS_COLORS,
} from '../constants/paymentTransactions';

// Assets
import styles from './StatusLabel.module.scss';

interface StatusLabelProps {
  status?: string;
  color?: string;
  text?: string;
  type?:
    | 'subscription'
    | 'statement'
    | 'product'
    | 'invoice'
    | 'transfer'
    | 'paymentPlan'
    | 'singlePayment'
    | 'subscriptionBuyer'
    | 'singlePaymentBuyer'
    | 'paymentTransaction';
  client?: boolean;
}

export const StatusLabel = ({
  status,
  color,
  text,
  type,
  client,
}: StatusLabelProps): React.ReactElement => {
  let finalColor = color || variables.lightygray;
  let finalText = text || '?';

  if (type === 'subscription') {
    finalColor = STATUS_COLORS[status || ''];
    finalText = STATUS_ES[status || ''] || finalText;
  } else if (type === 'statement') {
    finalColor = S_STATUS_COLORS[status || ''];
    finalText = client ? CLIENT_STATUS[status || ''] : S_STATUS_ES[status || ''] || finalText;
  } else if (type === 'product') {
    finalColor = P_STATUS_COLORS[status || ''];
    finalText = P_STATUS_ES[status || ''] || finalText;
  } else if (type === 'invoice') {
    finalColor = INVOICE_COLORS[status || ''];
    finalText = INVOICE_ES[status || ''] || finalText;
  } else if (type === 'transfer') {
    finalColor = SHK_STATUS_COLORS[status || ''];
    finalText = SHK_STATUS_ES[status || ''] || finalText;
  } else if (type === 'paymentPlan') {
    finalColor = PP_STATUS_COLORS[status || ''];
    finalText = PP_STATUS_ES[status || ''] || finalText;
  } else if (type === 'singlePayment') {
    finalColor = SP_STATUS_COLORS[status || ''];
    finalText = SP_STATUS_ES[status || ''] || finalText;
  } else if (type === 'subscriptionBuyer') {
    finalColor = SB_STATUS_COLORS[status || ''];
    finalText = SB_STATUS_ES[status || ''] || finalText;
  } else if (type === 'singlePaymentBuyer') {
    finalColor = SINGLE_PAYMENT_STATUS_COLORS[status || ''];
    finalText = SINGLE_PAYMENT_STATUS_ES[status || ''] || finalText;
  } else if (type === 'paymentTransaction') {
    finalColor = PT_STATUS_COLORS[status || ''];
    finalText = PT_STATUS_ES[status || ''] || finalText;
  } else {
    finalColor = variables[status || ''] || finalColor;
  }

  return (
    <span className={styles.statusLabel} style={{ backgroundColor: finalColor }}>
      {finalText}
    </span>
  );
};

export default StatusLabel;
