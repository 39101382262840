export const REGIONS = [
  ['AP', 'Arica y Parinacota'],
  ['TA', 'Tarapacá'],
  ['AN', 'Antofagasta'],
  ['AT', 'Atacama'],
  ['CO', 'Coquimbo'],
  ['VS', 'Valparaíso'],
  ['RM', 'Región Metropolitana'],
  ['LI', "O'Higgins"],
  ['ML', 'Maule'],
  ['NB', 'Ñuble'],
  ['BI', 'Biobío'],
  ['AR', 'Araucanía'],
  ['LR', 'Los Ríos'],
  ['LL', 'Los Lagos'],
  ['AI', 'Aysén'],
  ['MA', 'Magallanes'],
];

export const REGIONS_ES: { [key: string]: string } = Object.fromEntries(REGIONS);
