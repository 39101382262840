import React from 'react';
import { useSelector } from '../../../app/hooks';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { es } from 'yup-locales';

yup.setLocale(es);

// Types
import { ApiObject, Buyer, ExtraField } from '../../../app/type';
import variables from '../../../common/styles/variables.module.scss';

// Features
import { SellerState } from '../sellerSlice';

// Components
import { Grid, Button, TextField, CircularProgress, Typography } from '@mui/material';

// API
import { sellerApi } from '../../../common/api';
import { useSnackbar } from 'notistack';

const BuyerSchema = yup.object().shape({
  name: yup.string().min(3).max(64).required().label('Nombre'),
  email: yup.string().email().required().label('Correo'),
});

interface BuyerInfo {
  id?: string;
  company_id: string;
  name: string;
  email: string;
  extra_fields: { [key: string]: string };
}

interface BuyerFormProps {
  initialData?: BuyerInfo;
  actionLabel?: string;
  onSuccess?: (data: ApiObject<Buyer>, extraFields?: { [key: string]: string }) => void;
  extraActions?: React.ReactElement;
  loading?: boolean;
  setLoading: (l: boolean) => void;
  questions: ExtraField[];
}

export const BuyerForm = (props: BuyerFormProps): React.ReactElement => {
  const { company } = useSelector(({ seller }: { seller: SellerState }) => seller);
  const { enqueueSnackbar } = useSnackbar();
  const { initialData, actionLabel = 'Guardar', onSuccess = () => null, extraActions } = props;

  const formik = useFormik<BuyerInfo>({
    initialValues:
      initialData ||
      ({
        company_id: company?.id,
        name: '',
        email: '',
        extra_fields: {},
      } as BuyerInfo),
    validationSchema: BuyerSchema,
    onSubmit: (buyerInfo: BuyerInfo, { setErrors }: any) => {
      props.setLoading(true);
      const request = props.initialData?.id ? sellerApi.buyers.update : sellerApi.buyers.create;
      request({ data: buyerInfo })
        .then((data: ApiObject<Buyer>) => onSuccess(data, buyerInfo.extra_fields))
        .catch((err: any): void => {
          if (err?.response?.status === 400) {
            setErrors(err.response.data?.meta?.errors);
          } else {
            enqueueSnackbar('Ocurrió un error, vuelva a intentarlo', { variant: 'error' });
          }
        })
        .finally(() => {
          props.setLoading(false);
        });
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            required
            id="name"
            label="Nombre"
            type="text"
            autoComplete="name"
            variant="outlined"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            required
            id="email"
            name="email"
            label="Correo"
            type="email"
            autoComplete="email"
            variant="outlined"
            value={formik.values.email || ''}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} marginTop={2}>
        {props.questions.length !== 0 && (
          <Grid item xs={12}>
            <Typography variant="h5" color={variables.primaryDarkerColor}>
              <b>Datos Adicionales</b>
            </Typography>
          </Grid>
        )}
        {props.questions.map((object: ExtraField) => (
          <Grid item xs={12} md={6} key={object.id}>
            <TextField
              fullWidth
              required={object.mandatory}
              id={formik.values.extra_fields[object.id]}
              name={formik.values.extra_fields[object.id]}
              label={object.question}
              type="text"
              autoComplete="formik.values.extra_fields[key]"
              variant="outlined"
              value={formik.values.extra_fields[object.id] || ''}
              onChange={(event) =>
                formik.setFieldValue('extra_fields', {
                  ...formik.values.extra_fields,
                  [object.id]: event.target.value,
                })
              }
              error={formik.touched.extra_fields && Boolean(formik.errors.extra_fields)}
              helperText={formik.touched.extra_fields && formik.errors.extra_fields}
            />
          </Grid>
        ))}

        <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
          {extraActions}
          <Button
            disableElevation
            size="large"
            variant="contained"
            color="primary"
            type="submit"
            disabled={props.loading}
            className="loader"
          >
            {props.loading && <CircularProgress size={20} />}
            {actionLabel}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
