import {
  ApiObject,
  ApiList,
  Buyer,
  SubscriptionBuyer,
  Statement,
  PaymentPlan,
  SinglePaymentBuyer,
} from '../../../app/type';

import client from '../client';

interface CreateData {
  data: Partial<Buyer>;
}
export const create = (data: CreateData): Promise<ApiObject<Buyer>> => {
  return client({
    method: 'post',
    url: '/v1/buyers',
    data,
  })
    .then(({ data: body }: { data: ApiObject<Buyer> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ createError: error });
      throw error;
    });
};

interface UpdateData {
  data: Partial<Buyer>;
}
export const update = (data: UpdateData): Promise<ApiObject<Buyer>> => {
  return client({
    method: 'put',
    url: `/v1/buyers/${data.data.id}`,
    data,
  })
    .then(({ data: body }: { data: ApiObject<Buyer> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ updateError: error });
      throw error;
    });
};

export const list = (
  companyId: string,
  query?: string,
  page?: number,
  filterParams?: { [key: string]: any }
): Promise<ApiList<Buyer>> => {
  return client({
    method: 'get',
    url: `/v1/buyers`,
    params: { company_id: companyId, query, page: page || 1, filter_params: filterParams },
  })
    .then(({ data: body }: { data: ApiList<Buyer> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ listError: error });
      throw error;
    });
};

export const show = (buyerId: string): Promise<ApiObject<Buyer>> => {
  return client({
    method: 'get',
    url: `/v1/buyers/${buyerId}`,
  })
    .then(({ data: body }: { data: ApiObject<Buyer> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ showError: error });
      throw error;
    });
};

export const subscriptionBuyers = (
  buyerId: string,
  query?: string,
  page?: number,
  filterParams?: { [key: string]: string | Date | number | null | undefined }
): Promise<ApiList<SubscriptionBuyer>> => {
  return client({
    method: 'get',
    url: `/v1/buyers/${buyerId}/subscription_buyers`,
    params: { query, page: page || 1, filter_params: filterParams || {} },
  })
    .then(({ data: body }: { data: ApiList<SubscriptionBuyer> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ subscriptionsError: error });
      throw error;
    });
};

export const singlePaymentBuyers = (
  buyerId: string,
  query?: string,
  page?: number,
  filterParams?: { [key: string]: string | Date | number | null | undefined }
): Promise<ApiList<SinglePaymentBuyer>> => {
  return client({
    method: 'get',
    url: `/v1/buyers/${buyerId}/single_payment_buyers`,
    params: { query, page: page || 1, filter_params: filterParams || {} },
  })
    .then(({ data: body }: { data: ApiList<SinglePaymentBuyer> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ subscriptionsError: error });
      throw error;
    });
};

export const statements = (
  buyerId: string,
  query?: string,
  page?: number,
  filterParams?: { [key: string]: string | Date | number | null | undefined }
): Promise<ApiList<Statement>> => {
  return client({
    method: 'get',
    url: `/v1/buyers/${buyerId}/statements`,
    params: { buyer_id: buyerId, query, page: page || 1, filter_params: filterParams || {} },
  })
    .then(({ data: body }: { data: ApiList<Statement> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ statementsError: error });
      throw error;
    });
};

export const subscriptionBuyerDebt = (buyerId: string): Promise<ApiList<SubscriptionBuyer>> => {
  return client({
    method: 'get',
    url: `/v1/buyers/${buyerId}/subscription_buyers_debt`,
  })
    .then(({ data: body }: { data: ApiList<SubscriptionBuyer> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ statementsError: error });
      throw error;
    });
};

export const paymentPlanDebt = (buyerId: string): Promise<ApiList<PaymentPlan>> => {
  return client({
    method: 'get',
    url: `/v1/buyers/${buyerId}/payment_plans_debt`,
  })
    .then(({ data: body }: { data: ApiList<PaymentPlan> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ statementsError: error });
      throw error;
    });
};

export const totalBuyers = (companyId?: string): Promise<{ total: number }> => {
  return client({
    method: 'get',
    url: `/v1/buyers/total_buyers`,
    params: { company_id: companyId },
  })
    .then(({ data: body }: { data: { total: number } }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ statementsError: error });
      throw error;
    });
};

export const createGroup = (data: string[]): Promise<{ message: string }> => {
  return client
    .post('/v1/buyers/pay_buyer_debt', { ids: data })
    .then(({ data: body }: { data: { message: string } }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ showError: error });
      throw error;
    });
};

export const paymentPlans = (
  buyerId: string,
  query?: string,
  page?: number,
  filterParams?: { [key: string]: string | Date | number | null | undefined }
): Promise<ApiList<PaymentPlan>> => {
  return client({
    method: 'get',
    url: `/v1/buyers/${buyerId}/payment_plans`,
    params: { query, page: page || 1, filter_params: filterParams || {} },
  })
    .then(({ data: body }: { data: ApiList<PaymentPlan> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ paymentPlansError: error });
      throw error;
    });
};

export const generateExcel = (
  parentId?: string,
  query?: string,
  filterParams?: { [key: string]: any }
): Promise<{ message: string }> => {
  return client({
    method: 'post',
    url: `/v1/buyers/generate_excel`,
    params: { company_id: parentId, query: query, filter_params: filterParams },
  })
    .then(({ data: body }: { data: { message: string } }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ changeStatusError: error });
      throw error;
    });
};

export default {
  create,
  update,
  list,
  show,
  subscriptionBuyers,
  singlePaymentBuyers,
  statements,
  subscriptionBuyerDebt,
  paymentPlanDebt,
  createGroup,
};
