import variables from '../styles/variables.module.scss';

export const STATUS = [
  ['pending', 'Pendiente'],
  ['paid', 'Pagado'],
  ['expired', 'En deuda'],
  ['updated', 'Al día'],
  ['refunded', 'Devuelto'],
  ['processing_payment', 'Procesando pago'],
  ['processing_refund', 'Procesando devolución'],
  ['refunded_manually', 'Devuelto manualmente'],
];

export const CLIENT_STATUS = Object.fromEntries([
  ['pending', 'Por cobrar'],
  ['paid', 'Pagada'],
  ['expired', 'En deuda'],
  ['refunded', 'Devuelta'],
  ['processing_refund', 'Procesando devolución'],
  ['refunded_manually', 'Devuelta manualmente'],
]);

export const STATUS_COLORS = Object.fromEntries([
  ['pending', variables.pending],
  ['expired', variables.error],
  ['paid', variables.success],
  ['updated', variables.success],
  ['refunded', variables.warning],
  ['processing_payment', variables.warning],
  ['processing_refund', variables.warning],
  ['refunded_manually', variables.warning],
]);

export const STATUS_ES = Object.fromEntries(STATUS);

export const PAYABLE_TYPES = Object.fromEntries([
  ['Subscription', 'Suscripción'],
  ['SinglePayment', 'Pago único'],
  ['SubscriptionBuyer', 'Suscripción'],
  ['SinglePaymentBuyer', 'Pago único'],
  ['PaymentPlan', 'Plan de pago'],
]);
