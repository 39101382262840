// React, packages and api
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { sellerApi } from '../../../common/api';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';

// Slice and utils
import { useSelector, useDispatch } from '../../../app/hooks';
import { SellerState, setProduct } from '../sellerSlice';
import { formatCurrency, setDocumentTitle } from '../../../common/utils';

// MUI
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import Grid from '@mui/material/Grid';

// Styles and assets
import { ReactComponent as ProductsIcon } from '../../../assets/images/seller/products_icon.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBox } from '@fortawesome/free-solid-svg-icons';
import styles from './Index.module.scss';
import variables from '../../../common/styles/variables.module.scss';
import { faBell, faBellSlash, faTrashCan } from '@fortawesome/free-regular-svg-icons';

// Componentes
import InfoBox from '../../../common/components/InfoBox';
import Stepper from '../../../common/components/Stepper';
import ResourceList from '../../../common/components/ResourceList';
import StatusLabel from '../../../common/components/StatusLabel';

// Types
import { Product } from '../../../app/type';
import PopUp from '../../../common/components/PopUp';
import { Button } from '@mui/material';

const ProductIndex = (): React.ReactElement => {
  setDocumentTitle('Productos');
  const { company } = useSelector(({ seller }: { seller: SellerState }) => seller);
  const isMobile = useMediaQuery(`(max-width:${variables.breakpointMedium})`);
  const dispatch = useDispatch();
  const history = useHistory();
  const productRef = useRef<{ reloadResource: () => void }>();
  const [indexBoxes, setIndexBoxes] = useState<{ [key: string]: number }>({});
  const { enqueueSnackbar } = useSnackbar();
  const [activateDialogOpen, setActivateDialogOpen] = useState<boolean>(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const [productRes, setProductRes] = useState<Product | null>(null);

  useEffect(() => {
    if (company?.id) {
      sellerApi.products
        .indexBoxes(company.id)
        .then((data) => setIndexBoxes(data))
        .catch(() =>
          enqueueSnackbar('Ha ocurrido un problema al obtener las estadísticas de los productos', {
            variant: 'warning',
          })
        );
    }
  }, [company]);

  const changeStatus = (res: Product | null, resStatus: string | null) => {
    if (res && resStatus) {
      sellerApi.products
        .update({ product: { id: res?.id, status: resStatus } })
        .then(() => {
          enqueueSnackbar('Servicio actualizado', { variant: 'success' });
          productRef?.current?.reloadResource?.();
        })
        .catch(() => {
          enqueueSnackbar('Ocurrió un error al actualizar el servicio', { variant: 'error' });
        });
    }
  };

  const softDelete = (res: Product | null) => {
    if (res) {
      sellerApi.products
        .destroy(res?.id)
        .then(() => {
          enqueueSnackbar('Producto eliminado', { variant: 'success' });
          productRef?.current?.reloadResource?.();
        })
        .catch(() => {
          enqueueSnackbar(
            'El producto no puede ser eliminado porque tiene servicios con pagos completados',
            {
              variant: 'error',
            }
          );
        });
    }
  };

  // const downloadExcel = (res: Product) => {
  //   sellerApi.products
  //     .generate_excel(res.id)
  //     .then(() => {
  //       enqueueSnackbar('Se ha enviado un excel del producto', { variant: 'success' });
  //     })
  //     .catch(() => {
  //       enqueueSnackbar('Ocurrió un error al enviar el excel del producto', { variant: 'error' });
  //     });
  // };

  const openActivateDialog = (res: Product) => {
    setActivateDialogOpen(true);
    setProductRes(res);
  };

  const closeActivateDialog = () => {
    changeStatus(productRes, productRes?.status == 'active' ? 'inactive' : 'active');
    setActivateDialogOpen(false);
  };

  const openDeleteDialog = (res: Product) => {
    setDeleteDialogOpen(true);
    setProductRes(res);
  };

  const closeDeleteDialog = () => {
    softDelete(productRes);
    setDeleteDialogOpen(false);
  };

  const boxes = (width?: number) => [
    <InfoBox
      key={1}
      title="TOTAL DE PRODUCTOS"
      data={indexBoxes.total_products || 0}
      kind="info2"
      icon={<FontAwesomeIcon icon={faBox} className="icon" />}
      style={styles.firstInfoContainer}
      width={width}
    />,
    <InfoBox
      key={2}
      title="Crear Producto"
      function={() => history.push('products/new')}
      kind="link"
      image="https://storage.googleapis.com/onlypays-public/assets/images/girl%20and%20boy%20working%20together%20in%20front%20of%20laptop.svg"
      style={styles.linkContainer}
      width={width}
    />,
  ];

  return (
    <>
      <div className={styles.start}>
        <div className={styles.startHeader}>
          <ProductsIcon />
          <Typography variant={isMobile ? 'h5' : 'h4'}>
            <span className={styles.startTitle}>Productos</span>
          </Typography>
        </div>
        <Typography variant="body1">
          Podrás inscribir tus <b>productos</b> en tus suscripciones y pagos únicos.
        </Typography>
      </div>

      {isMobile ? (
        <Fragment>
          <Button
            className={styles.toButton}
            onClick={() => history.push('products/new')}
            variant="contained"
          >
            Crear Producto
          </Button>
          <Stepper assets={boxes(12).slice(0, -1)} />
        </Fragment>
      ) : (
        <Grid container className={styles.containerGrid} flexWrap={'nowrap'}>
          {boxes().map((box) => box)}
        </Grid>
      )}

      <ResourceList
        title="Productos"
        queryFields="Nombre"
        setResource={setProduct}
        getResourceList={sellerApi.products.list}
        resourceParent={company}
        excelDownloadMethod={(query, parentId, filterParams) =>
          sellerApi.products.generateExcel(parentId, query, filterParams)
        }
        listHeaders={[
          { key: 'name', label: 'Nombre' },
          { key: 'external_id', label: 'Referencia externa' },
          { key: 'status', label: 'Estado' },
          { key: 'value', label: 'Valor' },
        ]}
        listMobileHeaders={[
          { key: 'name' },
          { key: 'external_id' },
          { key: 'status' },
          { key: 'value' },
        ]}
        listColumns={{
          name: (res: Product) => res.name,
          value: (res: Product) => formatCurrency(res.value, res.currency),
          status: (res: Product) => <StatusLabel status={res.status} type="product" />,
          external_id: (res: Product) => res.external_id || '-',
        }}
        listActionsHeaders={(res?: Product) => {
          if (res)
            return [
              { key: 'show', label: 'Ver Producto' },
              res.status == 'active'
                ? { key: 'change_status', label: 'Inactivar', icon: faBellSlash }
                : { key: 'change_status', label: 'Activar', icon: faBell },
              { key: 'delete', label: 'Eliminar', icon: faTrashCan, color: 'error' },
            ];
          return [null];
        }}
        listActions={{
          show: (res: Product) => {
            dispatch(setProduct(res));
            history.push(`/seller/products/${res.id}`);
          },
          delete: (res: Product) => openDeleteDialog(res),
          change_status: (res: Product) =>
            res.status == 'active' ? openActivateDialog(res) : changeStatus(res, 'active'),
        }}
        innerRef={productRef}
        filtersKey="products_index"
        persistFilters
      />
      <PopUp
        state={{ open: deleteDialogOpen, setOpen: setDeleteDialogOpen }}
        title={
          <Typography variant="h6" align="center">
            Advertencia
          </Typography>
        }
        content={
          <Typography sx={{ marginTop: '20px' }} variant="body1" align="center">
            Se eliminará el producto y se removerá de todas las suscripciones y pagos únicos que lo
            contengan, por lo tanto cambiará el valor de las cuotas que no se han pagado de estos
            servicios, a excepción de las cuotas vencidas que mantienen su valor original. Esta
            acción es irreversible. ¿Quieres continuar?
          </Typography>
        }
        extraActions={[
          <Button onClick={closeDeleteDialog} color="error" variant="contained" key={1}>
            Si
          </Button>,
          <Button onClick={() => setDeleteDialogOpen(false)} variant="outlined" key={2}>
            No
          </Button>,
        ]}
      />
      <PopUp
        state={{ open: activateDialogOpen, setOpen: setActivateDialogOpen }}
        title={
          <Typography variant="h6" align="center">
            Advertencia
          </Typography>
        }
        content={
          <Typography sx={{ marginTop: '20px' }} variant="body1" align="center">
            Se removerá el producto de todas las suscripciones y pagos únicos que lo contengan, por
            lo tanto cambiará el valor de las cuotas que no se han pagado de estos servicios, a
            excepción de las cuotas vencidas que mantienen su valor original. Esta acción es
            irreversible. ¿Quieres continuar?
          </Typography>
        }
        extraActions={[
          <Button onClick={closeActivateDialog} color="error" variant="contained" key={1}>
            Si
          </Button>,
          <Button onClick={() => setActivateDialogOpen(false)} variant="outlined" key={2}>
            No
          </Button>,
        ]}
      />
    </>
  );
};

export default ProductIndex;
