import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { SinglePaymentBuyer, Statement, SubscriptionBuyer } from '../../app/type';

// Valida el rut con su cadena completa "XXXXXXXX-X"
export const validateRut = (full?: string | null): boolean => {
  if (full === null || full === undefined || full?.length === 0) return true;
  const dv = (T: number) => {
    let M = 0,
      S = 1;
    for (; T; T = Math.floor(T / 10)) S = (S + (T % 10) * (9 - (M++ % 6))) % 11;
    return S ? `${S - 1}` : 'K';
  };
  full = full
    .trim()
    .toUpperCase()
    .replace(/[^\d-K]/g, '');
  if (!/^[0-9]+[-]{1}[0-9K]{1}$/.test(full)) return false;
  const tmp = full.split('-');
  const digv = tmp[1];
  const rut = tmp[0];
  return dv(Number(rut)) === digv;
};

export const parseDate = (date: string): Date => {
  const date_splited: Array<string> = date.trim().split('/');
  const due_date: Date = new Date(
    parseInt(date_splited[2]),
    parseInt(date_splited[1]) - 1, // Junuary == 00
    parseInt(date_splited[0]),
    0,
    0,
    0,
    0
  );

  return due_date;
};

export const parseDatetime = (date: string | undefined): string | undefined => {
  if (date) {
    const m: Date = new Date(date);
    return m.toLocaleString('es-CL', { timeZone: 'America/Santiago' });
  }
};

export const checkStatus = (statement: Statement | undefined): string | undefined => {
  if (statement) {
    const temp_due_date = parseDate(statement.due_date);
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    if (statement.status === 'pending' && temp_due_date >= today) {
      statement.status = 'paid';
    }
    return statement.status;
  }
  return;
};

export const formatRUT = (rut?: string | null): string => {
  if (rut === null || rut === undefined) return '';
  const cleaned = rut.toUpperCase().replace(/[^\d](?![^K]?$)|[^\dK]/g, '');
  if (cleaned.length < 2) return cleaned;
  const dashed = `${cleaned.slice(0, -1)}-${cleaned.slice(-1)}`;
  const dotted = dashed.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  return dotted;
};

export const RUTFormater =
  (callback: (event: { target: { value: string } }) => void) =>
  (event: { target: { value: string } }): void => {
    event.target.value = formatRUT(event.target.value);
    callback(event);
  };

// Agrega separación de miles
export const formatNumber = (amount?: string | number): string => {
  if (amount === undefined) return '';
  return `${amount}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
};

export const formatDecimalNumber = (amount?: string | number): string => {
  if (amount === undefined) return '';
  return `${amount}`.replace(/(\d)(?=(\d{3})+(\.(\d){0,2})*$)/g, '$1,');
};

// Agrega separación de miles y signo $
export const formatCLP = (amount?: number | string): string => {
  if (amount === undefined) return '';
  return `$${formatNumber(amount)}`;
};

export const formatUSD = (amount?: number | string): string => {
  if (amount === undefined) return '';
  return `USD${formatCLP(amount)}`;
};

const limitNumberDecimals = (amount?: number | string) => {
  if (amount === undefined) return '';
  if (typeof amount === 'string') {
    const splitted = amount.split('.');
    splitted.length === 1 ? null : (splitted[1] = splitted[1].slice(0, 3));
    return splitted.join('.');
  } else if (amount % 1 != 0) {
    return amount.toFixed(3);
  } else return amount;
};

export const formatCurrency = (amount?: number | string, currency?: string): string => {
  amount = limitNumberDecimals(amount);
  if (currency === undefined) return formatCLP(amount);
  if (currency === 'UF') return `${formatDecimalNumber(amount)} ${currency}`;
  if (currency === 'USD') return formatUSD(amount);
  return formatCLP(amount);
};

export const decimalNumberFormatter = (amount?: string | number): string => {
  if (amount === undefined) return '';
  return `${amount}`
    .replace(/[^0-9.]/g, '')
    .replace(/\.{2,}/g, '.')
    .replace(/(\d*\.\d{1,3}).*/g, '$1');
};

export const plainNumberFormatter = (amount?: string | number): string => {
  if (amount === undefined) return '';
  return `${amount}`.replace(/[^\d]/g, '');
};

export const numberFormater =
  (callback: (event: { target: { value: string | number } }) => void) =>
  (event: { target: { value: string | number } }): void => {
    event.target.value = formatNumber(event.target.value);
    callback(event);
  };

export const useQuery = (): { [k: string]: string } => {
  const { search } = useLocation();

  return useMemo(() => {
    const query = new URLSearchParams(search);
    const result: { [k: string]: string } = {};
    for (const [key, value] of query.entries()) {
      result[key] = value;
    }
    return result;
  }, [search]);
};

export const loadScript = (id: string, url: string, callback?: () => void): void => {
  const isScriptExist = document.getElementById(id);

  if (!isScriptExist) {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = url;
    script.id = id;
    script.onload = function () {
      if (callback) callback();
    };
    document.body.appendChild(script);
  }

  if (isScriptExist && callback) callback();
};

export const checkUUID = (uuid: string): boolean => {
  const regexExp =
    /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
  return regexExp.test(uuid);
};

export const checkPaymentStatus = (
  statement: Statement | undefined,
  subscription: SubscriptionBuyer | SinglePaymentBuyer | undefined
): string | null => {
  if (!statement || !subscription) return null;
  if (subscription.completed_payments == 0) return statement.status;
  const tempDueDate = parseDate(statement.due_date);
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  if (statement.status == 'pending' && tempDueDate >= today) return 'updated';
  return statement.status;
};

export const initials = (phrase: string): string => {
  return phrase
    .trim()
    .split(' ')
    .reduce((prev, curr) => prev + curr[0].toUpperCase(), '');
};

export const capitalizeString = (phrase: string): string => {
  return phrase.replace(/\w\S*/g, (w) => w.replace(/^\w/, (c) => c.toUpperCase()));
};

export const setDocumentTitle = (title: string): string => (document.title = title);

export const formatDate = (date: string): string => {
  const dateToUse = new Date(`${date}T00:00:00`);
  return `${dateToUse.getDate()}/${dateToUse.getMonth() + 1}/${dateToUse.getFullYear()}`;
};

export const dateByString = (date: string): Date => {
  const expiration_date = date.split(' ')[0] || '';
  const expiration_time = date.split(' ')[1] || '';
  const et_date = new Date(
    parseInt(expiration_date.split('-')[0]),
    parseInt(expiration_date.split('-')[1]) - 1,
    parseInt(expiration_date.split('-')[2]),
    parseInt(expiration_time.split(':')[0]),
    parseInt(expiration_time.split(':')[1]),
    parseInt(expiration_time.split(':')[2])
  );
  return et_date;
};

export function navigateTop(yPos = 0, xPos = 0, behavior: any = undefined): void {
  window.scrollTo({ left: xPos, top: yPos, behavior: behavior });
}

export function compareDates(date2: string | Date): boolean {
  const date1 = new Date();
  const day = date1.getDate();
  const month = date1.getMonth() + 1;
  const year = date1.getFullYear();
  const currentDate = `${year}-${month}-${day}`;
  const d1 = new Date(currentDate);
  const d2 = new Date(date2);
  if (d1.getTime() >= d2.getTime()) {
    return true;
  } else {
    return false;
  }
}

export const formatDateDashboard = (date: Date): string => {
  const day = date.getDate();
  const month = date.getMonth() + 1;
  return `${date.getFullYear()}-${month <= 9 ? `0${month}` : month}-${day <= 9 ? `0${day}` : day}`;
};

export const formatAmountChart = (amount: number): string => {
  if (amount < 100000) return formatCLP(amount);
  if (amount < 1000000) return `${formatNumber(amount / 1000)}K`;
  return `${formatNumber(amount / 1000000)}MM`;
};

export const hexToRgb = (hex: string): { r: number; g: number; b: number } => {
  hex = hex.replace(/^#/, '');

  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  return { r, g, b };
};

export const downcase = (text: string | undefined): string => {
  if (typeof text === 'undefined') return '';
  return text.trim().toLowerCase();
};

export default {
  formatCurrency,
  validateRut,
  formatRUT,
  plainNumberFormatter,
  useQuery,
  loadScript,
  checkUUID,
  parseDate,
  checkPaymentStatus,
  initials,
  capitalizeString,
  setDocumentTitle,
  formatDate,
  navigateTop,
  compareDates,
  formatDateDashboard,
  formatAmountChart,
  hexToRgb,
  downcase,
};
