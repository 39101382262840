import React, { Fragment, useEffect, useState } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from '../../../app/hooks';

// Constants
import { RECURRENCE_ES } from '../../../common/constants/subscriptions';

// Utils
import { formatCurrency } from '../../../common/utils';

// Types
import {
  ApiObject,
  ApiList,
  Buyer,
  Product,
  Subscription,
  Statement,
  PaymentPlan,
  SubscriptionBuyer,
  SinglePaymentBuyer,
  SinglePayment,
  PaymentTransaction,
  // ExtraField,
} from '../../../app/type';

// API
import { sellerApi } from '../../../common/api';

// Features
import { SellerState, setBuyer, setProduct, setSubscription } from '../sellerSlice';

// Components
import { Grid, Paper, Typography, IconButton, CircularProgress } from '@mui/material';
import { Edit as EditIcon } from 'react-feather';

import Avatar from '../../../common/components/Avatar';
import ResourceList from '../../../common/components/ResourceList';
import StatusLabel from '../../../common/components/StatusLabel';

// Assets
import cStyles from '../../../common/styles/common.module.scss';
import { setSinglePayment } from '../sellerSlice';

const BuyerShow = (): React.ReactElement => {
  const { buyer } = useSelector(({ seller }: { seller: SellerState }) => seller);
  const [loading, setLoading] = useState<boolean>(false);
  const { buyerId } = useParams<{ buyerId: string }>();
  const dispatch = useDispatch();
  const [subscriptionBuyers, setSubscriptionBuyers] = useState<SubscriptionBuyer[] | []>([]);
  const history = useHistory();
  const onItemSubscription = (subscription?: Subscription) =>
    dispatch(setSubscription(subscription));
  const onItemSinglePayment = (singlePayment?: SinglePayment) =>
    dispatch(setSinglePayment(singlePayment));
  const onItemPaymentPlan = (product?: Product) => dispatch(setProduct(product));

  useEffect(() => {
    if (buyerId) {
      setLoading(true);
      sellerApi.buyers
        .show(buyerId)
        .then((data: ApiObject<Buyer>) => {
          dispatch(setBuyer(data.data));
        })
        .catch(console.error)
        .finally(() => {
          setLoading(false);
        });
    }
  }, [buyerId]);

  useEffect(() => {
    setLoading(true);
    sellerApi.buyers
      .subscriptionBuyers(buyerId)
      .then((data: ApiList<SubscriptionBuyer>) => {
        setSubscriptionBuyers(data.data);
      })
      .catch(console.error)
      .finally(() => setLoading(false));
  }, [buyerId]);

  return (
    <Fragment>
      <Grid container spacing={3} sx={{ mb: 3 }}>
        <Grid item xs={12}>
          <Paper className={cStyles.infoPaper}>
            {/* HEADER */}
            <Grid container>
              <Grid item xs={12}>
                <div className={cStyles.paperHeader}>
                  <Avatar
                    className={cStyles.paperHeaderAvatar}
                    text={buyer?.name}
                    context="buyer"
                  />
                  <div className={cStyles.paperHeaderContent}>
                    <Typography variant="h5" className="secondary">
                      {buyer?.name}
                    </Typography>
                    <Typography variant="h6" className="secondary">
                      {buyer?.email}
                    </Typography>
                  </div>
                  <div className={cStyles.paperHeaderActions}>
                    <IconButton
                      size="medium"
                      color="primary"
                      disabled={loading}
                      component={Link}
                      to={`/seller/buyers/${buyerId}/edit`}
                    >
                      {loading ? <CircularProgress size={20} /> : <EditIcon />}
                    </IconButton>
                  </div>
                </div>
              </Grid>
            </Grid>

            {/* INFO */}
            <Grid container className={cStyles.infoContainer}>
              <Grid item xs={12} md={6}>
                <Typography variant="caption">Nombre:</Typography>
                <Typography variant="h6">{buyer?.name || '-'}</Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="caption">Correo:</Typography>
                <Typography variant="h6">{buyer?.email || '-'}</Typography>
              </Grid>
              <Grid container item xs={12}>
                {subscriptionBuyers
                  .filter(
                    (subs: SubscriptionBuyer) =>
                      Object.keys(subs.subscription?.all_extra_fields || {}).length > 0
                  )
                  .map((subs: SubscriptionBuyer) => (
                    <Grid container item xs={12} key={subs.id} className={cStyles.infoContainer}>
                      <Grid item xs={12}>
                        <Typography variant="overline">{subs.subscription?.name}</Typography>
                      </Grid>
                      {Object.keys(subs.subscription?.all_extra_fields || {}).map((key: string) => (
                        <Grid item xs={12} md={6} lg={4} key={key}>
                          <Typography variant="caption">
                            {subs.subscription?.all_extra_fields[key]}:(
                            {subs.subscription?.mandatory_extra_fields[key]
                              ? 'Campo obligatorio'
                              : 'Campo no obligatorio'}
                            )
                          </Typography>
                          <Typography variant="h6">{subs.extra_fields[key] || '-'}</Typography>
                        </Grid>
                      ))}
                    </Grid>
                  ))}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>

      {/* SINGLE_PAYMENT */}
      <ResourceList
        title="Pagos únicos"
        queryFields="Nombre"
        getResourceList={sellerApi.buyers.singlePaymentBuyers}
        excelDownloadMethod={(query, parentId, filterParams) =>
          sellerApi.singlePayments.generateExcel('buyer', parentId, query, filterParams)
        }
        resourceParent={buyer}
        listHeaders={[
          { key: 'name', label: 'Nombre' },
          { key: 'value', label: 'Valor' },
          { key: 'state', label: 'Estado' },
        ]}
        listMobileHeaders={[{ key: 'name' }, { key: 'value' }, { key: 'state' }]}
        listColumns={{
          name: (res: SinglePaymentBuyer) => res.single_payment?.name,
          value: (res: SinglePaymentBuyer) =>
            formatCurrency(res.single_payment?.render_amount, res.single_payment?.currency),
          state: (res: SinglePaymentBuyer) => (
            <StatusLabel type="singlePaymentBuyer" status={res.status} />
          ),
        }}
        listActionsHeaders={() => [{ key: 'show', label: 'Ver servicio' }]}
        listActions={{
          show: (res: SinglePaymentBuyer) => {
            onItemSinglePayment(res.single_payment);
            history.push(`/seller/single_payments/${res.single_payment?.id}`);
          },
        }}
        filtersKey="buyer_show_single_payments"
      />

      {/* SUBSCRIPTIONS */}
      <ResourceList
        title="Suscripciones"
        queryFields="Nombre"
        getResourceList={sellerApi.buyers.subscriptionBuyers}
        excelDownloadMethod={(query, parentId, filterParams) =>
          sellerApi.subscriptions.generateExcel('buyer', parentId, query, filterParams)
        }
        resourceParent={buyer}
        listHeaders={[
          { key: 'name', label: 'Nombre' },
          { key: 'recurrence', label: 'Recurrencia' },
          { key: 'value', label: 'Valor' },
          { key: 'state', label: 'Estado' },
        ]}
        listMobileHeaders={[
          { key: 'name' },
          { key: 'recurrence' },
          { key: 'value' },
          { key: 'state' },
        ]}
        listColumns={{
          name: (res: SubscriptionBuyer) => res.subscription?.name,
          recurrence: (res: SubscriptionBuyer) => RECURRENCE_ES[res.subscription?.recurrence || ''],
          value: (res: SubscriptionBuyer) =>
            formatCurrency(res.subscription?.render_amount, res.subscription?.currency),
          state: (res: SubscriptionBuyer) => (
            <StatusLabel type="subscriptionBuyer" status={res.status} />
          ),
        }}
        listActionsHeaders={() => [{ key: 'show', label: 'Ver servicio' }]}
        listActions={{
          show: (res: SubscriptionBuyer) => {
            onItemSubscription(res.subscription);
            history.push(`/seller/subscriptions/${res.subscription?.id}`);
          },
        }}
        filtersKey="buyer_show_subscriptions"
      />

      {/* PAYMENT PLANS */}
      <ResourceList
        title="Planes de pago"
        queryFields="Nombre"
        getResourceList={sellerApi.buyers.paymentPlans}
        excelDownloadMethod={(query, parentId, filterParams) =>
          sellerApi.paymentPlans.generateExcel('buyer', parentId, query, filterParams)
        }
        resourceParent={buyer}
        listHeaders={[
          { key: 'productName', label: 'Nombre' },
          { key: 'buyerName', label: 'Nombre cliente' },
          { key: 'status', label: 'Estado' },
          { key: 'installments', label: 'Cuotas pagadas/cuotas totales' },
        ]}
        listMobileHeaders={[
          { key: 'productName' },
          { key: 'buyerName' },
          { key: 'status' },
          { key: 'installments' },
        ]}
        listColumns={{
          productName: (res) => res.product.name,
          buyerName: (res) => res.buyer.name,
          status: (res) => <StatusLabel status={res.status} type="paymentPlan" />,
          installments: (res) => `${res.total_paid_installments}/${res.total_installments}`,
        }}
        listActionsHeaders={() => [{ key: 'show', label: 'Ver servicio' }]}
        listActions={{
          show: (res: PaymentPlan) => {
            onItemPaymentPlan(res.product);
            history.push(`/seller/payment_plans/${res.id}`);
          },
        }}
      />

      {/* STATEMENTS */}
      <ResourceList
        title="Pagos"
        queryFields="Nombre servicio"
        getResourceList={sellerApi.buyers.statements}
        excelDownloadMethod={(query, parentId, filterParams) =>
          sellerApi.statements.statementsExcel('buyer', parentId, query, filterParams, true)
        }
        resourceParent={buyer}
        listHeaders={[
          { key: 'service_name', label: 'Servicio' },
          { key: 'amount', label: 'Monto' },
          { key: 'due_date', label: 'Fecha de vencimiento' },
          { key: 'payment_date', label: 'Fecha de pago' },
          { key: 'status', label: 'Estado' },
        ]}
        listMobileHeaders={[{ key: 'service_name' }, { key: 'due_date' }, { key: 'status' }]}
        listColumns={{
          service_name: (res: Statement) => res.service_name,
          amount: (res: Statement) => formatCurrency(res.amount),
          due_date: (res: Statement) => res.due_date,
          payment_date: (res: Statement) => res.payment_date || '-',
          status: (res: Statement) => <StatusLabel type="statement" status={res.status} />,
        }}
        getCollapseResources={sellerApi.statements.paymentTransactions}
        listCollapseHeaders={[
          { key: 'payment_date', label: 'Fecha' },
          { key: 'status', label: 'Estado' },
          { key: 'payment_method', label: 'Método de Pago' },
          { key: 'error', label: 'Descripción' },
        ]}
        listCollapseColumns={{
          payment_date: (res: PaymentTransaction) => res.payment_date,
          status: (res: PaymentTransaction) => (
            <StatusLabel type="paymentTransaction" status={res.status} />
          ),
          payment_method: (res: PaymentTransaction) => res.payment_method,
          error: (res: PaymentTransaction) =>
            res.status === 'accepted'
              ? 'Transacción aprobada'
              : res.error || 'Sin información del proveedor',
        }}
        listMobileCollapseHeaders={[
          { key: 'payment_date', label: 'Fecha' },
          { key: 'status', label: 'Estado' },
          { key: 'payment_method', label: 'Método de Pago' },
        ]}
        filtersKey="buyer_show_statements"
      />
    </Fragment>
  );
};

export default BuyerShow;
