export const MONTHS = Object.fromEntries([
  ['01', 'ENE'],
  ['02', 'FEB'],
  ['03', 'MAR'],
  ['04', 'ABR'],
  ['05', 'MAY'],
  ['06', 'JUN'],
  ['07', 'JUL'],
  ['08', 'AGO'],
  ['09', 'SEP'],
  ['10', 'OCT'],
  ['11', 'NOV'],
  ['12', 'DIC'],
]);

export const DESCRIPTIONS = Object.fromEntries([
  [
    'sales',
    'Suma del monto de todas las cuotas que se debían haber cobrado en el periodo seleccionado',
  ],
  ['gain', 'Suma del monto de todas las cuotas que se cobraron efectivamente en el periodo'],
  ['debt', 'Suma del monto de las cuotas que no se pudieron cobrar en el periodo seleccionado'],
  ['debtors', 'Total de personas que tienen deuda en el periodo seleccionado'],
  [
    'conversion_rate',
    'Tasa de cobro efectiva de las transacciones que tus clientes trataron de efectuar en el periodo',
  ],
  ['average_ticket', 'Monto promedio de las cuotas cobradas en el periodo seleccionado'],
]);
