import React from 'react';

import Typography from '@mui/material/Typography';

import styles from './Filter.module.scss';
import { formatCLP, formatDate } from '../../utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { omit } from 'lodash';

import { P_STATUS_ES as PRODUCT_STATUS } from '../../constants/products';
import { STATUS_ES as SUBSCRIPTION_STATUS } from '../../constants/subscriptions';
import { STATUS_ES as SINGLE_PAYMENT_STATUS } from '../../constants/singlePayments';
import { STATUS_ES as SUBSCRIPTION_BUYER_STATUS } from '../../constants/subscriptionBuyers';
import { STATUS_ES as SINGLE_PAYMENT_BUYER_STATUS } from '../../constants/singlePaymentBuyers';
import { RECURRENCE_ES } from '../../constants/subscriptions';
import {
  STATUS_ES as STATEMENT_STATUS,
  PAYABLE_TYPES as STATEMENT_TYPE,
} from '../../constants/statements';

interface TagsProps {
  tags: { [key: string]: any };
  setData: (args: { [key: string]: any }) => void;
}

export const Tags = (props: TagsProps): React.ReactElement => {
  const dictToUse = (context: string, innerContext?: string) => {
    if (context === 'recurrence') {
      return RECURRENCE_ES;
    } else if (context === 'payable_type') {
      return STATEMENT_TYPE;
    } else {
      // Se asume solo status por ahora
      if (innerContext === 'product') {
        return PRODUCT_STATUS;
      } else if (innerContext === 'subscription') {
        return SUBSCRIPTION_STATUS;
      } else if (innerContext === 'single_payment') {
        return SINGLE_PAYMENT_STATUS;
      } else if (innerContext === 'subscription_buyer') {
        return SUBSCRIPTION_BUYER_STATUS;
      } else if (innerContext === 'single_payment_buyer') {
        return SINGLE_PAYMENT_BUYER_STATUS;
      } else {
        return STATEMENT_STATUS;
      }
    }
  };

  const deleteTag = (context: string, data: any, innerContext?: string) => {
    let cleanData = data;
    if (
      Object.values(data)
        .map((value) => value === 'deactivated')
        .every((element) => element === true)
    ) {
      cleanData = Object.keys(data).reduce(
        (previous, current) => ({ ...previous, [current]: 'off' }),
        {}
      );
    }
    if (innerContext) {
      props.setData({
        ...props.tags,
        [context]: { ...props.tags[context], [innerContext]: cleanData },
      });
      return;
    }
    props.setData({ ...props.tags, [context]: cleanData });
  };

  return (
    <div className={styles.tagContainer}>
      {Object.keys(props.tags).map((key) => {
        if (key === 'price') {
          return (
            (props.tags[key].min || props.tags[key].max) && (
              <div className={styles.tag} key={key}>
                <Typography>
                  {formatCLP(props.tags[key].min)} - {formatCLP(props.tags[key].max)}
                </Typography>
                <div
                  className={styles.xMark}
                  onClick={() => deleteTag(key, omit(props.tags[key], ['min', 'max']))}
                >
                  <FontAwesomeIcon icon={faXmark} />
                </div>
              </div>
            )
          );
        } else if (key === 'recurrence') {
          return Object.keys(props.tags[key]).map(
            (innerKey) =>
              props.tags[key][innerKey] === 'activated' && (
                <div className={styles.tag} key={innerKey}>
                  <Typography>{dictToUse(key)[innerKey]}</Typography>
                  <div
                    className={styles.xMark}
                    onClick={() =>
                      deleteTag(key, { ...props.tags[key], [innerKey]: 'deactivated' })
                    }
                  >
                    <FontAwesomeIcon icon={faXmark} />
                  </div>
                </div>
              )
          );
        } else if (key === 'payable_type') {
          return Object.keys(props.tags[key]).map(
            (innerKey) =>
              props.tags[key][innerKey] === 'activated' && (
                <div className={styles.tag} key={innerKey}>
                  <Typography>{dictToUse(key)[innerKey]}</Typography>
                  <div
                    className={styles.xMark}
                    onClick={() =>
                      deleteTag(key, { ...props.tags[key], [innerKey]: 'deactivated' })
                    }
                  >
                    <FontAwesomeIcon icon={faXmark} />
                  </div>
                </div>
              )
          );
        } else if (['payment_date', 'due_date', 'subscription_date', 'created_at'].includes(key)) {
          const startDate = props.tags[key].start_date
            ? formatDate(props.tags[key].start_date)
            : '...';
          const finishDate = props.tags[key].finish_date
            ? formatDate(props.tags[key].finish_date)
            : '...';
          return !props.tags[key].start_date && !props.tags[key].finish_date ? null : (
            <div className={styles.tag} key={key}>
              <Typography>
                {startDate} - {finishDate}
              </Typography>
              <div className={styles.xMark} onClick={() => deleteTag(key, {})}>
                <FontAwesomeIcon icon={faXmark} />
              </div>
            </div>
          );
        } else if (key === 'status') {
          return Object.keys(props.tags[key]).map((contextKey) =>
            Object.keys(props.tags[key][contextKey]).map(
              (innerKey) =>
                props.tags[key][contextKey][innerKey] === 'activated' && (
                  <div className={styles.tag} key={innerKey}>
                    <Typography>{dictToUse(key, contextKey)[innerKey]}</Typography>
                    <div
                      className={styles.xMark}
                      onClick={() =>
                        deleteTag(
                          key,
                          { ...props.tags[key][contextKey], [innerKey]: 'deactivated' },
                          contextKey
                        )
                      }
                    >
                      <FontAwesomeIcon icon={faXmark} />
                    </div>
                  </div>
                )
            )
          );
        }
      })}
    </div>
  );
};

export default Tags;
