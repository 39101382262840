import client from '../client';

import { ApiList, Transfer } from '../../../app/type';

export const list = (page?: number): Promise<ApiList<Transfer>> => {
  console.log(page);
  return client({
    method: 'get',
    url: `/backoffice/transfers/list`,
    params: { page: page || 1 },
  })
    .then(({ data: body }: { data: ApiList<Transfer> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ listError: error });
      throw error;
    });
};
