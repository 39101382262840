import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from '../../../app/hooks';
import { SellerState } from '../sellerSlice';

// Components
import {
  Grid,
  Paper,
  Typography,
  TextField,
  Button,
  FormControl,
  FormGroup,
  FormControlLabel,
  Switch,
  CircularProgress,
} from '@mui/material';
import { useFormik } from 'formik';

import PreviewBox from '../../../common/components/PreviewPaymentView';
import { useSnackbar } from 'notistack';
import { sellerApi } from '../../../common/api';

// Assets
import styles from './CompanySeller.module.scss';
import cStyles from '../../../common/styles/common.module.scss';
import { PAYMENT_METHODS } from '../../../common/constants/company';
import { setDocumentTitle } from '../../../common/utils';

interface PaymentDesignInfo {
  id: string;
  background_color: string;
  company_id: string;
}

const PaymentLinksEdit = (): React.ReactElement => {
  setDocumentTitle('Configuración links de pago');
  const { loading, company } = useSelector(({ seller }: { seller: SellerState }) => seller);
  const history = useHistory();
  const [paymentMethodsChosen, setPaymentMethodsChosen] = useState<string[]>([]);
  const { enqueueSnackbar } = useSnackbar();

  const formik = useFormik<PaymentDesignInfo>({
    initialValues: company?.payment_design || {
      id: '',
      background_color: '#4653E3',
      company_id: company?.id || '',
    },
    onSubmit: (paymentDesignInfo: PaymentDesignInfo, { setErrors }: any) => {
      const request = company?.payment_design?.id
        ? sellerApi.payment_designs.update
        : sellerApi.payment_designs.create;

      request({ data: paymentDesignInfo })
        .then()
        .catch((err: any): void => {
          if (err?.response?.status === 400) {
            setErrors(err.response.data?.meta?.errors);
          } else {
            enqueueSnackbar('Ocurrió un error, vuelva a intentarlo', { variant: 'error' });
          }
        });
      sellerApi.paymentMethods
        .update(company?.id || '', paymentMethodsChosen)
        .then((data) => {
          setPaymentMethodsChosen(data.data.map((pm) => pm.kind));
          enqueueSnackbar('Información actualizada correctamente', { variant: 'success' });
        })
        .catch((err: any) => {
          console.error(err);
        });
    },
  });

  const handlePaymentMethodChange = (payment_method: string) => {
    if (paymentMethodsChosen?.includes(payment_method)) {
      setPaymentMethodsChosen(paymentMethodsChosen.filter((pm: string) => pm !== payment_method));
    } else {
      setPaymentMethodsChosen([...paymentMethodsChosen, payment_method]);
    }
  };

  useEffect(() => {
    if (!company) {
      history.replace('/seller/home');
    } else if (company) {
      sellerApi.paymentMethods.list(company.id).then((data) => {
        setPaymentMethodsChosen(data.data.map((pm) => pm.kind));
      });
    }
  }, [company?.id]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container>
        <Grid item xs={12} md={12}>
          <Paper className={styles.infoPaper}>
            <Grid container spacing={3} className={styles.mainContainer}>
              <Grid item xs={12} className={cStyles.paperHeader}>
                <div className={cStyles.paperHeaderContent}>
                  <Typography variant="h5">Estilo</Typography>
                  <Typography>
                    Puedes definir un color para personalizar el link de pago de tus servicios.
                  </Typography>
                </div>
              </Grid>
              <div className={styles.components}>
                <Grid item container xs={2} className={styles.companyDesignColors}>
                  <div>
                    <Typography mt={1}>Color empresa:</Typography>
                  </div>
                  <div>
                    <TextField
                      fullWidth
                      id="background_color"
                      type="color"
                      variant="outlined"
                      value={formik.values.background_color}
                      onChange={formik.handleChange}
                    />
                  </div>
                </Grid>
                <Grid item xs={10} className={styles.previewBoxContainer}>
                  <div className={styles.responsiveIframe}>
                    <PreviewBox
                      background_color={formik.values.background_color}
                      company={company}
                    />
                  </div>
                </Grid>
              </div>
            </Grid>
            <Grid container className={styles.paymentMethodsContainer}>
              <Grid item xs={12} className={cStyles.paperHeader}>
                <div className={cStyles.paperHeaderContent}>
                  <Typography variant="h5">Medios de pago</Typography>
                </div>
              </Grid>
              <Typography>
                Estos son los medios de pago con los cuales tus clientes pueden pagar tus servicios.
              </Typography>
              <FormControl component="fieldset" variant="standard">
                <FormGroup>
                  {Object.keys(PAYMENT_METHODS).map((payment_method: any) => (
                    <div key={payment_method}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={paymentMethodsChosen?.includes(payment_method)}
                            onChange={() => handlePaymentMethodChange(payment_method)}
                            name={payment_method}
                          />
                        }
                        label={PAYMENT_METHODS[payment_method]}
                      />
                    </div>
                  ))}
                </FormGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} mt={2} display="flex" justifyContent="center" alignItems="center">
              <Button
                disableElevation
                size="large"
                variant="contained"
                color="primary"
                type="submit"
                disabled={loading}
                className="loader"
              >
                {loading && <CircularProgress size={20} />}
                Guardar
              </Button>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </form>
  );
};

export default PaymentLinksEdit;
