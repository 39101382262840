import {
  FormControlLabel,
  FormGroup,
  RadioGroup,
  Radio,
  CircularProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Box,
  Modal,
  Container,
  Paper,
  Tooltip,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React, { useEffect, useRef, useState } from 'react';
import QRCode from 'react-qr-code';
import { getFintoc } from '@fintoc/fintoc-js';
import { Kushki } from '@kushki/js';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { es } from 'yup-locales';
import { intersection } from 'lodash';

import { useSelector } from 'react-redux';
import { ClientState } from '../clientSlice';

import PopUp from '../../../common/components/PopUp';
import { Typography } from '@mui/material';

import { clientApi } from '../../../common/api';
import { buyerApi } from '../../../common/api';
import {
  ApiList,
  Card,
  CardInfo,
  KushkiResponse,
  PAC,
  TokenKushkiPayRequest,
} from '../../../app/type';
import { useSnackbar } from 'notistack';

import styles from '../products/Product.module.scss';
import cStyles from '../../../common/styles/common.module.scss';
import { useHistory, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCreditCard, faWallet, faMoneyBill } from '@fortawesome/free-solid-svg-icons';
import webpayLogo from '../../../assets/images/seller/payment/webpay.svg';
import machLogo from '../../../assets/images/seller/payment/mach.svg';
import fintocLogo from '../../../assets/images/seller/payment/fintoc.svg';
import kushkiLogo from '../../../assets/images/seller/payment/kushki.svg';
import etPayLogo from '../../../assets/images/seller/payment/etpay.png';
import { hexToRgb } from '../utils';
import { useShopCartContext } from '../../../common/contexts/ShopCart';
import KushkiCardForm from '../../../common/components/KushkiCardForm';

interface PopUpProps {
  state: {
    open: boolean;
    setOpen: (s: boolean) => void;
  };
  amount: number;
  statements: string[];
  cards: Card[];
}

const kushki = new Kushki({
  merchantId:
    process.env.NODE_ENV === 'production'
      ? '1804b8f0b64549adb59dd76c6736124d'
      : 'c417cbc305344419a2c765e11ac7c7b3',
  inTestEnvironment: process.env.NODE_ENV !== 'production',
});

let chosenPaymentMethodTimer: number | undefined = undefined;
const paymentMethods = ['webpay_plus', 'mach', 'fintoc', 'kushki_single', 'et_pay_business'];
yup.setLocale(es);

export const PaymentsPopup = ({
  state,
  statements,
  amount,
  cards,
}: PopUpProps): React.ReactElement => {
  const [loading, setLoading] = useState<boolean>(false);
  const [availableToPay, setAvailableToPay] = useState<boolean>(false);
  const [selectedCard, setSelectedCard] = useState<string>('');
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<string>('');
  const [chosenPaymentMethod, setChosenPaymentMethod] = useState<any>({});
  const [machModalOpen, setMachModalOpen] = useState<boolean>(false);
  const [availablePaymentMethods, setAvailablePaymentMethods] = useState<string[]>([]);
  const { client, company } = useSelector(({ client }: { client: ClientState }) => client);
  const { cleanStatementsCart } = useShopCartContext();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const formRef = useRef(null);
  const rgbColor = company?.payment_design?.background_color
    ? hexToRgb(company?.payment_design?.background_color)
    : null;
  const cardsBackgroundColor = rgbColor
    ? `rgba(${rgbColor.r}, ${rgbColor.g}, ${rgbColor.b}, 0.1)`
    : '#edeeff';

  const cardSchema = yup.object().shape({
    card_number: yup
      .string()
      .required('Número de tarjeta requerido')
      .max(19, 'Debe tener un largo máximo de 16 dígitos'),
    name: yup.string().required('Nombre del titular es requerido'),
    expiration_date: yup
      .string()
      .required('Fecha de vencimiento requerida')
      .min(5, 'Fecha inválida'),
    cvv: yup.string().required('CVV requerido').min(3, 'CVV inválido'),
  });

  const formik = useFormik<CardInfo>({
    initialValues: {
      card_number: '',
      name: '',
      expiration_date: '',
      cvv: undefined,
    },
    validationSchema: () => cardSchema,
    onSubmit: () => {
      setLoading(true);
      kushki.requestToken(
        {
          amount: amount,
          currency: 'CLP',
          card: {
            name: formik.values.name,
            number: formik.values.card_number.split(' ').join(''),
            cvc: formik.values.cvv,
            expiryMonth: formik.values.expiration_date.slice(0, 2),
            expiryYear: formik.values.expiration_date.slice(3, 5),
          },
        } as TokenKushkiPayRequest,
        (response: KushkiResponse) => {
          if (!response.code) {
            payStatements(response.token);
            cleanStatementsCart();
          } else {
            setLoading(false);
            enqueueSnackbar('Ocurrió un error al procesar el pago', { variant: 'error' });
            console.error(response.error);
          }
        }
      );
    },
  });

  const paymentMethod = (event: string) => {
    setAvailableToPay(true);
    if (paymentMethods.includes(event)) {
      setSelectedPaymentMethod(event);
      setSelectedCard('');
    } else {
      setSelectedPaymentMethod('');
      setSelectedCard(event);
    }
  };

  const paymentMethodsToRender = [
    availablePaymentMethods.includes('webpay_plus') && (
      <div key={'webpay_plus'} className={styles.paymentMethod}>
        <div>
          <FormControlLabel
            checked={selectedPaymentMethod === 'webpay_plus'}
            value={'webpay_plus'}
            control={<Radio />}
            label={
              <div>
                <FontAwesomeIcon icon={faCreditCard} />
                <Typography>Tarjeta crédito o débito</Typography>
              </div>
            }
          />
        </div>
        <img src={webpayLogo} />
      </div>
    ),
    availablePaymentMethods.includes('kushki_single') && (
      <div className={styles.kushkiFormContainer}>
        <div key={'kushki_single'} className={styles.paymentMethod}>
          <div>
            <FormControlLabel
              checked={selectedPaymentMethod === 'kushki_single'}
              value={'kushki_single'}
              control={<Radio />}
              label={
                <div>
                  <FontAwesomeIcon icon={faCreditCard} />
                  <Typography>Tarjeta crédito o débito</Typography>
                </div>
              }
            />
          </div>
          <img src={kushkiLogo} />
        </div>
        {selectedPaymentMethod === 'kushki_single' && (
          <div className={styles.kushkiForm}>
            <KushkiCardForm formik={formik} />
          </div>
        )}
      </div>
    ),
    availablePaymentMethods.includes('mach') && (
      <div key={'mach'} className={styles.paymentMethod}>
        <div>
          <FormControlLabel
            checked={selectedPaymentMethod === 'mach'}
            value={'mach'}
            control={<Radio />}
            label={
              <div>
                <FontAwesomeIcon icon={faWallet} />
                <Typography>Prepago</Typography>
              </div>
            }
          />
        </div>
        <img src={machLogo} />
      </div>
    ),
    availablePaymentMethods.includes('fintoc') && (
      <div key={'fintoc'} className={styles.paymentMethod}>
        <div>
          <FormControlLabel
            checked={selectedPaymentMethod === 'fintoc'}
            value={'fintoc'}
            control={<Radio />}
            label={
              <div>
                <FontAwesomeIcon icon={faMoneyBill} />
                <Typography>Transferencia Bancaria</Typography>
              </div>
            }
          />
        </div>
        <img src={fintocLogo} />
      </div>
    ),
    availablePaymentMethods.includes('et_pay_business') && (
      <div key={'et_pay_business'} className={styles.paymentMethod}>
        <div>
          <FormControlLabel
            checked={selectedPaymentMethod === 'et_pay_business'}
            value={'et_pay_business'}
            control={<Radio />}
            label={
              <div>
                <FontAwesomeIcon icon={faMoneyBill} />
                <Typography>Transferencia de empresa</Typography>
              </div>
            }
          />
        </div>
        <img src={etPayLogo} />
      </div>
    ),
  ];

  const payStatements = (token?: string) => {
    clientApi.paymentTransactions
      .payOtherMethod(statements, client?.id || '', selectedPaymentMethod, token)
      .then((body: any) => {
        if (body.data?.redirect) {
          body.data?.redirect_to
            ? history.push(body.data?.redirect_to)
            : history.push('/client/payments?payment_success=true');
        }
        setChosenPaymentMethod(body.data);
      })
      .catch((error) => {
        state.setOpen(false);
        setLoading(false);
        enqueueSnackbar(error.response.data?.data.redirect_to, { variant: 'error' });
      });
  };

  const handlePayment = () => {
    if (selectedCard) {
      setLoading(true);
      setAvailableToPay(false);
      clientApi.paymentTransactions
        .payWithCard(statements, client?.id || '', selectedCard)
        .then((data) => {
          resetStates();
          setSelectedCard;
          if (data.message === 'ok') {
            history.replace('/client/payments?payment_success=true');
            state.setOpen(false);
            setLoading(false);
            cleanStatementsCart();
          } else {
            enqueueSnackbar('Ocurrió un error al procesar el pago', { variant: 'error' });
          }
        })
        .catch((error: any) => {
          console.error({ listError: error });
          state.setOpen(false);
          enqueueSnackbar('Ocurrió un error al procesar el pago', { variant: 'error' });
          throw error;
        })
        .finally(() => {
          setLoading(false);
          setAvailableToPay(false);
        });
    } else {
      if (selectedPaymentMethod === 'kushki_single') {
        formik.submitForm();
      } else {
        setLoading(true);
        payStatements();
      }
    }
  };

  const hiddenPaymentMethodsForms = () => {
    if (
      ['webpay_plus', 'oneclick'].includes(chosenPaymentMethod?.payment_method) &&
      chosenPaymentMethod?.url
    ) {
      return (
        <form method="POST" action={chosenPaymentMethod.url} ref={formRef}>
          <input type="hidden" name="TBK_TOKEN" value={chosenPaymentMethod.token} />
        </form>
      );
    } else if (chosenPaymentMethod?.payment_method === 'mach' && chosenPaymentMethod?.url) {
      return (
        <Modal open={machModalOpen} onClose={() => setMachModalOpen(false)}>
          <Container maxWidth="sm" style={{ margin: 'auto' }}>
            <Paper className={`${cStyles.modal} ${styles.modal}`}>
              <div className={styles.modalHeader}>
                <Typography variant="h4">Para pagar escanea el siguiente código</Typography>
              </div>
              <QRCode title="Pago MACH" value={chosenPaymentMethod?.url} />
              <Typography variant="h6">o si estás en tu teléfono</Typography>
              <Button variant="contained" color="primary" href={chosenPaymentMethod?.url}>
                abre MACH
              </Button>
              <Typography variant="h6">
                y espera a ser redirigido a la vista de confirmación.
              </Typography>
            </Paper>
          </Container>
        </Modal>
      );
    }
  };

  const resetStates = () => {
    setLoading(false);
    setSelectedCard('');
    setSelectedPaymentMethod('');
    setAvailableToPay(false);
  };

  useEffect(() => {
    if (['webpay_plus', 'oneclick'].includes(chosenPaymentMethod?.payment_method)) {
      if (chosenPaymentMethod?.url && formRef?.current) {
        // @ts-expect-error: formRef.current could be undefined
        formRef?.current.submit();
      }
    } else if (chosenPaymentMethod?.payment_method === 'mach') {
      setMachModalOpen(true);
      setLoading(false);
      if (chosenPaymentMethodTimer) clearInterval(chosenPaymentMethodTimer);
      chosenPaymentMethodTimer = setInterval(() => {
        buyerApi.mach
          .check_status(chosenPaymentMethod?.payment_id)
          .then((data: any) => {
            if (['COMPLETED', 'CONFIRMED'].includes(data.status)) {
              if (chosenPaymentMethodTimer) clearInterval(chosenPaymentMethodTimer);
              history.replace('/client/payments?payment_success=true');
              state.setOpen(false);
              cleanStatementsCart();
            } else if (['EXPIRED', 'REVERSED'].includes(data.status)) {
              if (chosenPaymentMethodTimer) clearInterval(chosenPaymentMethodTimer);
              history.push('/client/error');
            }
          })
          .catch();
      }, 5000) as unknown as number;
    } else if (chosenPaymentMethod?.payment_method === 'fintoc_payment') {
      getFintoc().then((Fintoc: any) => {
        const widget = Fintoc.create({
          widgetToken: chosenPaymentMethod?.widget_token,
          publicKey: chosenPaymentMethod?.public_key,
          holderType: 'individual',
          product: 'payments',
          webhookUrl: chosenPaymentMethod?.webhook_url,
          onSuccess: () => {
            buyerApi.fintoc
              .check_status(chosenPaymentMethod?.payment_id)
              .then(() => {
                history.replace('/client/payments?payment_success=true');
                state.setOpen(false);
                cleanStatementsCart();
              })
              .catch(() => history.push('/client/error'));
          },
        });
        setLoading(false);
        widget.open();
      });
    } else if (chosenPaymentMethod?.payment_method === 'et_pay_business') {
      location.href = chosenPaymentMethod.redirect_url;
    }

    if (chosenPaymentMethod?.payment_method !== 'mach' && chosenPaymentMethodTimer) {
      clearInterval(chosenPaymentMethodTimer);
    }

    return () => {
      if (chosenPaymentMethodTimer) clearInterval(chosenPaymentMethodTimer);
    };
  }, [chosenPaymentMethod, formRef]);

  const renderPac = (pac: PAC) => (
    <div
      className={`${styles.card} ${styles.disabledCard}`}
      key={pac.id}
      style={{
        background: cardsBackgroundColor,
      }}
    >
      <div>
        <FormControlLabel
          disabled={pac.status === 'pending_approval'}
          value={pac.id}
          control={<Radio />}
          label={
            <div className={styles.cardRow}>
              <div className={styles.cardIcon}>
                <FontAwesomeIcon icon={faCreditCard} className={styles.cardIcon} />
              </div>
              <Typography>{`${pac.account_type} •••• ${pac.account_number?.slice(-4)}`}</Typography>
            </div>
          }
        />
      </div>
    </div>
  );

  useEffect(() => {
    if (company?.payment_methods) {
      setAvailablePaymentMethods(company.payment_methods);
    }
  }, [company]);

  return (
    <PopUp
      state={state}
      title={
        <div className={styles.titlePaymentPopup}>
          <Typography sx={{ fontSize: '20px' }}>¿Cómo quieres pagar?</Typography>
        </div>
      }
      content={
        <div className={styles.paymentPopupContainer}>
          {company?.payment_methods?.includes('oneclick') && (
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Tus tarjetas</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {client && cards.length > 0 ? (
                  <FormGroup>
                    <RadioGroup
                      name="Cards options"
                      value={selectedCard}
                      onChange={(event: any) => paymentMethod(event.target.value)}
                    >
                      {cards.map((card: Card) => (
                        <div
                          className={styles.card}
                          key={card.id}
                          style={{
                            background: cardsBackgroundColor,
                          }}
                        >
                          <div>
                            <FormControlLabel
                              value={card.id}
                              control={<Radio />}
                              label={
                                <div className={styles.cardRow}>
                                  <div className={styles.cardIcon}>
                                    <FontAwesomeIcon
                                      icon={faCreditCard}
                                      className={styles.cardIcon}
                                    />
                                  </div>
                                  <Typography>{`XXXX XXXX XXXX ${card.last_four_digits}`}</Typography>
                                </div>
                              }
                            />
                          </div>
                        </div>
                      ))}
                    </RadioGroup>
                  </FormGroup>
                ) : (
                  <Typography>
                    No tienes tarjetas guardadas. Agrega una <Link to={'/client/cards'}>aquí</Link>
                  </Typography>
                )}
              </AccordionDetails>
            </Accordion>
          )}
          {/* {intersection(company?.payment_methods, ['recurrent_fintoc', 'recurrent_khipu']).length >
            0 && (
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Tus PAC</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {client && PACs.length > 0 ? (
                  <FormGroup>
                    <RadioGroup
                      name="PACs options"
                      value={selectedCard}
                      onChange={(event: any) => paymentMethod(event.target.value)}
                    >
                      {PACs.map((pac: PAC) =>
                        pac.status === 'pending_approval' ? (
                          <Tooltip
                            title={
                              <Typography variant="subtitle2">
                                Este PAC aún no ha sido activado en el banco, por lo tanto no está
                                habilitado para realizar pagos
                              </Typography>
                            }
                            arrow
                            key={pac.id}
                          >
                            {renderPac(pac)}
                          </Tooltip>
                        ) : (
                          renderPac(pac)
                        )
                      )}
                    </RadioGroup>
                  </FormGroup>
                ) : (
                  <Typography>
                    No tienes PACs <b>activos</b> inscritos. Agrega uno{' '}
                    <Link to={'/client/cards'}>aquí</Link>
                  </Typography>
                )}
              </AccordionDetails>
            </Accordion>
          )} */}
          {!paymentMethodsToRender.every((item) => item === false) && (
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>
                  {company?.automatic_payment_methods ? 'Otro medio de pago' : 'Medios de pago'}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <FormGroup className={styles.formRadio}>
                  <RadioGroup
                    name="Payment Method"
                    value={selectedPaymentMethod}
                    onChange={(event: any) => paymentMethod(event.target.value)}
                  >
                    {paymentMethodsToRender}
                  </RadioGroup>
                </FormGroup>
              </AccordionDetails>
            </Accordion>
          )}
          <Button
            className={styles.payButton}
            style={{
              backgroundColor: availableToPay
                ? company?.payment_design?.background_color
                  ? company?.payment_design?.background_color
                  : '#4653e3'
                : '#9f9f9f',
            }}
            onClick={handlePayment}
            disabled={!availableToPay || loading}
          >
            {loading ? (
              <div className={styles.whiteSpinnerContainer}>
                <Box>
                  <CircularProgress
                    size={'20px'}
                    sx={{
                      color: company?.payment_design?.background_color
                        ? company?.payment_design?.background_color
                        : '#4653e3',
                    }}
                  />
                </Box>
              </div>
            ) : (
              <Typography>Pagar</Typography>
            )}
          </Button>
          {hiddenPaymentMethodsForms()}
        </div>
      }
    />
  );
};

export default PaymentsPopup;
